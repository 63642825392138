import { Typography } from "@mui/material";
import { getRewardColor } from "../../utils/rewards";

const RewardTier = ({ tier, isFirstTier, ...props }) => {
  return (
    <Typography
      variant="h5"
      gutterBottom={false}
      color={
        isFirstTier ? "var(--bronze-tier)" : `var(${getRewardColor(tier)})`
      }
      paddingBottom={0}
      {...props}
    >
      {tier}
    </Typography>
  );
};

export default RewardTier;