import { useEffect } from "react";
import ReactGA from "react-ga4";

const GoogleAnalytics = ({testMode}) => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      testMode: testMode, // testMode will not send any data to GA
    });
  }, [testMode]);

  return null;
};

export default GoogleAnalytics;
