import PropTypes from "prop-types";
import { memo } from "react";
import css from "../styles/ErrorMessage.module.css";

const ErrorMessage = ({ errorMessage, style = {} }) => {
  return (
    <div style={{ ...style }} className={css["msg-container"]}>
      <p className={css["msg-title"]}>{errorMessage}</p>
    </div>
  );
};

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default memo(ErrorMessage);
