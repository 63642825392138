import { getUser, isProLoggedIn, isRetailLoggedIn } from "../utils/jwt";
import css from "../styles/FreeShippingBanner.module.css";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { smsOptInShowingState } from "../atoms/userAtoms";

const FreeShippingBanner = () => {
  const isPro = isProLoggedIn();
  const isRetail = isRetailLoggedIn();
  const user = getUser();
  const smsOptInShowing = useRecoilValue(smsOptInShowingState);

  const shippingCost = useMemo(() => {
    if (isPro && user?.rewardsTier === null) {
      return "$250";
    } else if (isRetail) {
      return "$100";
    }
  }, [isPro, isRetail, user?.rewardsTier]);

  if (user?.freeShipping === true) {
    if (isPro || user?.proAccountId?.length > 0) {
      return null;
    }
  }

  if (smsOptInShowing) {
    return null;
  }

  return (
    <>
      {(isPro && user?.rewardsTier === null) || isRetail ? (
        <div className={css["shipping-sub-title-main"]}>
          <h6 className={css["shipping-sub-title"]}>
            ORDERS OVER {shippingCost} SHIP FOR FREE!
          </h6>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default FreeShippingBanner;
