import { Box, Button, Typography } from "@mui/material";
import css from "../../styles/SkuInfo.module.css";
import { useSetRecoilState, useRecoilState } from "recoil";
import { NotifyMeMessageState, NotifyMeSKUState, OpenNotifyMeState } from "../../atoms/productAtoms";
import NotificationSuccess from "../products/NotificationSuccess";
import {
  NOTIFY_ME,
  OUT_OF_STOCK
} from "../products/Constants";

const OutOfStock = ({ selectedSku }) => {
    const setNotifyMe = useSetRecoilState(OpenNotifyMeState);
    const setNotifyMeSKU = useSetRecoilState(NotifyMeSKUState);
    const notifyMeMessage = useRecoilState(NotifyMeMessageState);

  const handleNotifyMe = () => {
    setNotifyMe(true);
    setNotifyMeSKU(selectedSku);
  };

  return (
    <Box className={css.outOfStockBox}>
      <Button
        className={css.addBtn}
        onClick={handleNotifyMe}
        variant="btn-secondary"
      >
        {NOTIFY_ME}
      </Button>
      <Typography className={css.outOfStockText} color="error">
        {OUT_OF_STOCK}
      </Typography>
      <NotificationSuccess
        message={notifyMeMessage}
      />
    </Box>
  );
};

export default OutOfStock;
