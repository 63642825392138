import { regenBasic } from "../utils/axios";
import { getUserId } from "../utils/jwt";

//Team
export const fetchTeam = async () => {
  const { data } = await regenBasic.get(`/teamMember/team`);
  return data;
};

//UAC
export const fetchUAC = async () => {
  const { data } = await regenBasic.get(`/teamMember/uac`);
  return data;
};

// Blogs
export const fetchBlogPosts = async (page = 1, perPage = 10) => {
  const url = `/blogPosts?page=${page}&perPage=${perPage}`;
  const { data } = await regenBasic.get(url);
  return data;
};

export const fetchBlogPostById = async (postId) => {
  const { data } = await regenBasic.get(`/blogPosts/${postId}`);
  return data;
};

//Calendar
export const fetchCalendarTabs = async () => {
  const { data } = await regenBasic.get("/calendar/tabs");
  return data;
};

export const fetchCalendarEvents = async (type) => {
  const { data } = await regenBasic.get(`/calendar/tabs/${type}`);
  return data;
};

export const fetchEventDetails = async (id) => {
  const userId = getUserId();
  const url = userId
    ? `/calendarItems/${id}/${userId}`
    : `/calendarItems/${id}`;
  const { data } = await regenBasic.get(url);
  return data;
};

//Protocol
export const fetchProtocols = async (page = 1, perPage = 10) => {
  const url = `/protocols?page=${page}&perPage=${perPage}`;
  const { data } = await regenBasic.get(url);
  return data;
};

export const fetchProtocolById = async (id) => {
  const { data } = await regenBasic.get(`/protocols/${id}`);
  return data;
};

//Webinar
export const fetchWebinar = async (page = 1, perPage = 10) => {
  const url = `/webinars?page=${page}&perPage=${perPage}`;
  const { data } = await regenBasic.get(url);
  return data;
};

export const fetchWebinarById = async (id) => {
  const { data } = await regenBasic.get(`/webinars/${id}`);
  return data;
};

//Resources
export const fetchResources = async () => {
  const { data } = await regenBasic.get("/resources");
  return data;
};

// Page Header
export const fetchPageContent = async (pageId) => {
  const { data } = await regenBasic.get(`/page/content/${pageId}`);
  return data;
};

// Page
export const fetchPage = async (pageId) => {
  const { data } = await regenBasic.get(`/pages/${pageId}`);
  return data;
};

//Feature Page
export const fetchFeaturePage = async (pageId) => {
  const { data } = await regenBasic.get(`/featurePages/${pageId}`);
  return data;
};

//Education School

export const getSchoolCourses = async () => {
  const { data } = await regenBasic.get(`/schoolCourses`);
  return data;
};

export const getSchoolCourseById = async (id) => {
  const { data } = await regenBasic.get(`/schoolCourses/${id}`);
  return data;
};

//Regimens
export const fetchRegimens = async (page = 1, perPage = 10) => {
  const url = `/regimens?page=${page}&perPage=${perPage}`;
  const { data } = await regenBasic.get(url);
  return data;
};

export const fetchRegimenById = async (id) => {
  const { data } = await regenBasic.get(`/regimens/${id}`);
  return data;
};

//Retail Locations
export const getRetailLocations = async () => {
  const { data } = await regenBasic.get(`/retailLocations`);
  return data;
};
