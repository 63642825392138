import Grid from "@mui/material/Grid";
import RewardIcon from "../components/Rewards/RewardIcon";
import { BorderLinearProgress } from "../components/Rewards/Rewards.styled";
import RewardTier from "../components/Rewards/RewardTier";
import { getRewardColor } from "../utils/rewards";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function EducationGAAINRewards(props) {
  const { nextTierMinSpend } = props;
  const linearProgress = nextTierMinSpend
    ? (1 - nextTierMinSpend / 500) * 100
    : 0;
  const navigate = useNavigate();

  const handleBuyNow = () => {
    window?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate(`/products`);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <RewardIcon tier="pro" />
          <RewardTier tier="Pro" />
        </Grid>
        <Grid item xs={8} sx={{ marginTop: "19px" }}>
          <BorderLinearProgress
            variant="determinate"
            value={linearProgress}
            progresscolor={getRewardColor("pro")}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <RewardIcon tier="bronze" />
          <RewardTier tier="Bronze" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="btn-secondary"
            sx={{ mt: "10px" }}
            onClick={handleBuyNow}
          >
            Shop Now
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default EducationGAAINRewards;
