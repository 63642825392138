import { IconButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";

function ProductUneligible() {
  return (
    <Tooltip
      title={<UneligibleMessage />}
      placement="bottom"
      enterTouchDelay={0}
    >
      <IconButton component="span" sx={{ marginTop: "-4px", height: "36px" }}>
        <InfoOutlinedIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
}

const UneligibleMessage = () => {
  return (
    <Typography variant="subtitle2" style={{ paddingBottom: "0rem" }}>
      This product is excluded for points accrual and/or redemption
    </Typography>
  );
};

export default ProductUneligible;
