import axios from "axios";
import {
  getRegenBasicHeader,
  getRegenJwtHeader,
  getRegenAdminJwtHeader,
  clearLocalStorage,
} from "../utils/jwt";
import getDeviceId from "./deivceId";
const baseURL =
  (process.env.REACT_APP_API_URL || "") + process.env.REACT_APP_BASE_URL;

const baseURLV2 = process.env.REACT_APP_API_V2_URL || "";

const headers = {
  "Browser-Id": getDeviceId(),
};

const regenRequestHandler = (request) => {
  request.headers = getRegenJwtHeader(headers);
  return request;
};

const regenAdminRequestHandler = (request) => {
  request.headers = getRegenAdminJwtHeader(headers);
  return request;
};

const requestHandler = (request) => {
  return request;
};

const responseSuccessHandler = (response) => {
  return response;
};

const responseErrorHandler = (error) => {
  if (error?.response?.status === 401) {
    clearLocalStorage();
    window.location = "/login";
  }

  if (error?.response?.data) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error);
};

//===========REGEN BASIC =======================//

export const regenBasic = axios.create({
  baseURL,
  headers: getRegenBasicHeader(headers),
});

regenBasic.interceptors.request.use((request) => requestHandler(request));

regenBasic.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

//===========REGEN JWT =======================//

export const regen = axios.create({
  baseURL,
});

regen.interceptors.request.use((request) => regenRequestHandler(request));

regen.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

//===========REGEN ADMIN =======================//

export const regenAdmin = axios.create({
  baseURL,
});

regenAdmin.interceptors.request.use((request) =>
  regenAdminRequestHandler(request)
);

regenAdmin.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

//===========REGEN BASIC V2 =======================//

export const regenBasicV2 = axios.create({
  baseURL: baseURLV2,
  // headers: getRegenBasicHeader(headers),
});

regenBasicV2.interceptors.request.use((request) => requestHandler(request));

regenBasicV2.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);
