import Sku from "./Sku";
import Spacer from "../Spacer";
import { useMemo } from "react";
import Box from "@mui/material/Box";
import CartActions from "./CartActions";
import css from "../../styles/SkuInfo.module.css";
import { useGetCartBySku } from "../../hooks/useManageCart";
import SkuPriceWithDiscount from "./SkuPriceWithDiscount";
import { StyledBodyRegular } from "../../styled";

const SkuInfo = ({ skuSet, sku, selectedSku, handleSkuSelection }) => {
  const { data } = useGetCartBySku(sku);

  const skuData = useMemo(() => {
    return skuSet?.find((item) => item.sku === selectedSku) || {};
  }, [selectedSku, skuSet]);

  return (
    <>
      {skuSet?.length > 0 && (
        <Box>
          <StyledBodyRegular className={css.bodyRegular}>
            Size:
          </StyledBodyRegular>
          <Box className={css.skuContainer}>
            {skuSet?.map((item) => (
              <Sku
                key={item.sku}
                sku={item}
                handleSelectSku={handleSkuSelection}
                selectedSku={selectedSku}
              />
            ))}
          </Box>
          <Spacer height={20} />
          <SkuPriceWithDiscount sku={skuData} />
          <Spacer height={20} />
          <CartActions selectedSku={skuData} cart={data} />
          <Spacer height={50} />
        </Box>
      )}
    </>

  );
};

export default SkuInfo;
