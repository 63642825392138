import { refreshToken } from "../services";
import { useQuery } from "react-query";
import { useEffect } from "react";

const RefreshJwt = () => {
  const { refetch } = useQuery("refreshToken", () => refreshToken(), {
    refetchInterval: 25 * 60 * 1000,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return null;
};

export default RefreshJwt;
