import React from "react";
import { useRecoilValue } from "recoil";
import { RewardsNextTierMinSpendState } from "../atoms/cartAtoms";
import css from "../styles_b2b/HowToAccessReward.module.css";
import EducationGAAINRewards from "../components_web/EducationGAAINRewards";
import Box from "@mui/material/Box";
import { getUser } from "../utils/jwt";
import Button from "@mui/material/Button";
import GAAINLogo from "../assets/img/GAAINGlyMed.png";
import { useNavigate } from "react-router-dom";

const HowToAccessReward = () => {

  const user = getUser();
  const { rewardsTier } = user;
  const nextTierMinSpend = useRecoilValue(RewardsNextTierMinSpendState);
  return (
    <Box>
      <h3>How do I get access?</h3>
      {rewardsTier ? (
        <UserWithGAAINAccess />
      ) : (
        <UserWithNoAccess nextTierMinSpend={nextTierMinSpend} />
      )}
    </Box>
  );
};


const UserWithGAAINAccess = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/education")
  }

  return (
    <Box className={css.rewardInfo}>
      <p className={css.infoTitle}>YOU ALREADY HAVE ACCESS TO GAAIN</p>
      <img className={css.gaainLogo} src={GAAINLogo} alt="GAAIN Logo" />
      <Button
        fullWidth
        variant="btn-secondary"
        sx={{ mt: "10px" }}
        onClick={handleNavigation}
      >
        Go to GAAIN
      </Button>
    </Box>
  );
};

const UserWithNoAccess = ({ nextTierMinSpend }) => {
  return (
    <Box className={css.rewardInfo}>
      <p className={css.infoTitle}>GET FREE ACCESS WHEN YOU REACH BRONZE!</p>
      <p className={css.infoSubTitle}>
        Spend ${nextTierMinSpend?.toFixed(2)} to reach bronze.
      </p>
      <EducationGAAINRewards nextTierMinSpend={nextTierMinSpend} />
    </Box>
  );
};

export default HowToAccessReward;
