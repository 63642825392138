import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { useUpdateCart } from "../../hooks/useManageCart";
import css from "./CartThumbnail.module.css";
import QuantityList from "./QuantityList";
import { OUT_OF_STOCK } from "../products/Constants";
import { getFormattedPrice } from "../../utils";
import ProductUneligible from "../checkout/ProductUneligible";
import { useNavigate } from "react-router-dom";
import Image from "../shared/Image";
import { StyledBodyLight } from "../../styled";
import { getHasImpersonate, checkPermission } from "../../utils/jwt";
import { PERMISSSIONS } from "../checkout/Constants";

function CartThumbnail({ data }) {
  const mutation = useUpdateCart();
  const navigate = useNavigate();
  const hasImpersonate = getHasImpersonate();
  const hasEditPerm = checkPermission(PERMISSSIONS.EDIT_ORDER_ITEM);
  const disableEdit = hasImpersonate && !hasEditPerm;

  const handleRemove = (evt) => {
    evt.preventDefault();
    mutation.mutate({
      itemsRemoved: [data?.sku],
      rewardPoints: 0,
      couponCode: "",
      shipVia: "",
      shippingRate: "",
      shipName: "",
      discount: 0,
      isPercent: false,
    });
  };

  const handleProductClick = () => {
    if (data?.active) {
      navigate(`/products/${data?.sku}`);
    }
  };

  const cursor = data?.active ? "pointer" : "default";

  return (
    <Grid container mt={1} className={css["cart-thumbnail"]}>
      <Grid item xs={4} sm={4} p={1}>
        <div
          style={{
            width: "100%",
            height: "100%",
            cursor: cursor,
          }}
        >
          <Image
            src={`${data?.thumbnail}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${data?.thumbnail}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={data?.shortName}
            loading="lazy"
            className={css["cart-thumbnail-image"]}
            onClick={handleProductClick}
          />
        </div>
      </Grid>
      <Grid item xs={8} sm={8} className={css["cart-thumbnail-info"]}>
        <Grid item xs={12} display="flex">
          <StyledBodyLight className={css.title}>
            {data?.shortName} ({data?.sku})
          </StyledBodyLight>
          {!data?.rewardEligible && <ProductUneligible />}
        </Grid>
        <Grid item xs={12} display="flex" alignItems="center">
          <Grid item xs={5} display="flex" flexDirection="row">
            {data?.inventory && data?.inventory > 0 && (
              <QuantityList item={data} />
            )}
          </Grid>
          <Grid
            item
            xs={7}
            className={css.priceBox}
          >
            <CartItemPrice item={data} precision={2} />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          pl={3.4}
          sx={{ pt: { xs: 1, md: 0 } }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledBodyLight
            style={{
              color: data?.inventory ? "var(--blue)" : "var(--danger)",
              padding: 0,
            }}
          >
            {data?.inventory && data?.inventory > 0 ? null : OUT_OF_STOCK}
          </StyledBodyLight>
          <StyledBodyLight style={{ padding: 0 }}>
            <Link
              disabled={disableEdit}
              component="button"
              color="#E71414"
              underline="none"
              onClick={handleRemove}
              style={{
                cursor: "pointer",
                height: "auto",
                color: disableEdit ? "var(--gray-400)" : "",
              }}
            >
              Remove
            </Link>
          </StyledBodyLight>
        </Grid>
      </Grid>
    </Grid>
  );
}

CartThumbnail.propTypes = {
  data: PropTypes.object,
};

const CartItemPrice = ({ item, precision }) => {
  if (!(item?.inventory > 0)) {
    return null;
  }

  if (item.quantity === 1) {
    return SingleItemPrice({ item, precision });
  } else {
    return MultiItemPrice({ item, precision });
  }
};

const SingleItemPrice = ({ item, precision }) => {
  if (item?.isFree) {
    return (
      <StyledBodyLight className={css.priceText}>
        FREE
      </StyledBodyLight>
    );
  }

  return (
    <StyledBodyLight className={css.priceText}>
      {`$${getFormattedPrice(item?.discountedAmount, precision)}`}
    </StyledBodyLight>
  );
};

const MultiItemPrice = ({ item, precision }) => {
  return (
    <>
      <StyledBodyLight className={css.priceText}>
        <b>{`$${getFormattedPrice(item?.totalWithoutTax, precision)}\u00a0`}</b>
      </StyledBodyLight>
      <StyledBodyLight className={css.priceText}>
        {`($${getFormattedPrice(item?.discountedAmount, precision)} each)`}
      </StyledBodyLight>
    </>
  );
};

export default CartThumbnail;
