import {regenBasic} from "../utils/axios";
import {getHasImpersonate, getUserId, parseJwt} from "../utils/jwt";
import getDeviceId from "../utils/deivceId";

const deviceId = getDeviceId();

//Cart
export const getCart = async (showTaxes = false, returnToCart = false) => {
  const userId = getUserId() || deviceId;
  if (!userId) {
    return [];
  }
  let url = userId ? `/cart/${userId}` : `/cart`;
  url = showTaxes ? returnToCart ? `${url}?showTaxes=true&returnToCart=true` :
      `${url}?showTaxes=true` : returnToCart ? `${url}?returnToCart=true` : url;
  const response = await regenBasic.get(url);
  return response?.data;
};

//Get cart as per Sku
export const getCartBySku = async (sku) => {
  if (!sku) {
    return [];
  }
  const userId = getUserId() || deviceId;
  const url = userId ? `/cart/${userId}/${sku}` : `/cart/${sku}`;
  const response = await regenBasic.get(url);
  return response?.data;
};

export const updateCart = async (data) => {
  const userId = getUserId() || deviceId;
  const url = userId ? `/cart/${userId}` : `/cart`;
  const {admUserEmail, role} = parseJwt() || {};
  let dataWithAdmUserEmail = {
    ...data,
    admUserEmail: getHasImpersonate() && admUserEmail ? admUserEmail : "",
    admRole: getHasImpersonate() && role ? role : "",
  }
  const response = await regenBasic.put(url, dataWithAdmUserEmail);
  return response?.data;
};

export const deleteFromCart = async (data) => {
  const userId = getUserId() || deviceId;
  const url = userId ? `/cart/${userId}` : `/cart`;
  const response = await regenBasic.delete(url, data);
  return response?.data;
};

export const setFreeItem = async (data) => {
  const userId = getUserId() || deviceId;
  if (!userId) {
    return [];
  }
  const url = `/cart/${userId}/freeitem`;
  const response = await regenBasic.put(url, data);
  return response?.data;
};

export const deleteFreeItem = async () => {
  const userId = getUserId() || deviceId;
  if (!userId) {
    return [];
  }
  const url = `/cart/${userId}/freeitem`;
  const response = await regenBasic.delete(url);
  return response?.data;
}
