import { useQuery } from "react-query";
import { getRewards } from "../services/checkout";
import { getRewardsForCourse } from "../services";
import { getUserId } from "../utils/jwt";

export const useRegenRewards = () => {
  return useQuery("rewards", getRewards);
};

export const useGetRewardsForCourse = (courseId) => {
  const userId = getUserId();
  return useQuery(
    ["rewardsForCourse", courseId, userId],
    () => getRewardsForCourse(courseId),
    {
      enabled: !!courseId && !!userId,
    }
  );
};
