import PropTypes from "prop-types";

const VideoPlayer = ({ src, poster, preload, autoPlay = false }) => {
  return (
    <video
      poster={poster ? poster : null}
      width="100%"
      height="auto"
      controls
      autoPlay={autoPlay}
      preload={preload ? preload : "none"}
      controlsList="nodownload"
    >
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  poster: PropTypes.string,
  preload: PropTypes.string,
  autoPlay: PropTypes.bool,
};
