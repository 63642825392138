import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { NotifyMeMessageState, NotifyMeSKUState, OpenNotifyMeState } from "../../atoms/productAtoms";
import {
  EMAIL_ADDRESS,
  NOTIFICATION_HEADER,
  NOTIFICATION_HINT,
  NOTIFICATION_MESSAGE,
  SIZE,
} from "./Constants";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useState } from "react";
import { useUpdateNotify } from "../../hooks/useManageNotify";
import { validateNotifyEmail } from "../../utils";
import Image from "../shared/Image";
import { normalizeEmail } from "../../components_web/helper";

function NotifyMe() {
  const [OpenNotifyMe, setOpenNotifyMe] = useRecoilState(OpenNotifyMeState);
  const setNotifyMeMessage = useSetRecoilState(NotifyMeMessageState);
  const [email, setEmail] = useState("");
  const [isNotifyOther, setIsNotifyOther] = useState(false);
  const mutation = useUpdateNotify();
  const product = useRecoilValue(NotifyMeSKUState);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpenNotifyMe(false);
    setEmail("");
    setIsNotifyOther(false);
  };

  const handleSubmitClick = () => {
    mutation.mutate({
      sku: product?.sku,
      email: email,
      isNewProduct: isNotifyOther,
    }, {
      onSuccess: (data) => {
        setNotifyMeMessage(data?.message);
      }
    });
    setOpenNotifyMe(false);
    setEmail("");
    setIsNotifyOther(false);
  };

  const handleEmailChange = (event) => {
    setEmail(normalizeEmail(event?.target?.value));
  };

  const handleNotifyOther = (event) => {
    setIsNotifyOther(event?.target?.checked);
  };

  return (
    <>
      <Dialog
        open={OpenNotifyMe}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
        aria-labelledby="Notify-Me"
        BackdropProps={{
          style: {
            opacity: 0.4,
          },
        }}
        PaperProps={{
          style: { display: "flex", flexDirection: "row-reverse" },
        }}
        fullScreen={fullScreen}
      >
        <IconButton
          onClick={handleClose}
          className="notify-me-close"
          sx={{ minWidth: "2rem" }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item mt={{ xs: 5, sm: 3 }}>
            <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
              {NOTIFICATION_HEADER}
            </Typography>
          </Grid>
          <Grid
            container
            item
            sx={{ flexDirection: "column", alignItems: "center" }}
            columns={1}
          >
            <Grid item>
              <Image
                src={product?.thumbnail}
                alt="test"
                loading="lazy"
                className="product-notify-view"
              />
            </Grid>
            <Grid item mt={2} width={{ xs: "85%", md: "50%" }}>
              <Typography
                variant="h5"
                sx={{
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {product?.shortName}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {SIZE}: {product?.productSize}
              </Typography>
              <TextField
                label={EMAIL_ADDRESS}
                variant="filled"
                id="notify_email"
                size="small"
                onChange={handleEmailChange}
                value={email}
                fullWidth
                error={!validateNotifyEmail(email)}
              />
              <Grid item mt={2}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={NOTIFICATION_MESSAGE}
                  className="product-notify-checkbox"
                  checked={isNotifyOther}
                  onChange={handleNotifyOther}
                />
              </Grid>
              <Grid mt={2}>
                <Button
                  disabled={!email || !validateNotifyEmail(email)}
                  variant="btn-primary-fluid"
                  id="btn_submit"
                  data-cy="submit_button"
                  onClick={handleSubmitClick}
                >
                  SUBMIT
                </Button>
              </Grid>
              <Grid item mt={3}>
                <Typography sx={{ fontSize: "12px" }}>
                  {NOTIFICATION_HINT}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default NotifyMe;
