import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { memo } from "react";
import Paper from "@mui/material/Paper";
import LoaderStyle from "../styles/Loader.module.css";

//can be used as simple loader when no args are passed
//or can work as backdrop loader with open and backdrop args
const Loader = ({ open, backdrop = false, style = {} }) => {
  if (backdrop) {
    return (
      <Backdrop className={LoaderStyle["loader-backdrop"]} open={open}>
        <Paper
          style={{ ...style }}
          className={LoaderStyle["loader-backdrop-indicator"]}
        >
          <CircularProgress color="inherit" size={25} />
        </Paper>
      </Backdrop>
    );
  }
  return (
    <div className={LoaderStyle["loader-indicator"]} style={{ ...style }}>
      <Paper className={LoaderStyle["loader-wrapper"]}>
        <CircularProgress color="inherit" size={25} />
      </Paper>
    </div>
  );
};

Loader.propTypes = {
  backdrop: PropTypes.bool,
  open: PropTypes.bool,
  style: PropTypes.object,
};

export default memo(Loader);
