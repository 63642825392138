import { regenBasic } from "../utils/axios";
import { getUserId } from "../utils/jwt";

export const getRegenRewards = async (selectedYear) => {
  const userId = getUserId();
  const timeZone = encodeURIComponent(Intl.DateTimeFormat()?.resolvedOptions()?.timeZone);
  if(!userId) {
    return;
  }
  const url = selectedYear
    ? `/users/${userId}/rewards/info/${timeZone}?year=${selectedYear}`
    : `/users/${userId}/rewards/info/${timeZone}`;
  const { data } = await regenBasic.get(url);
  return data;
};

export const getFAQs = async () => {
  const { data } = await regenBasic.get(`/faqs`);
  return data;
};

export const getRewardsForCourse = async (courseId) => {
  const userId = getUserId();
  const { data } = await regenBasic.get(
    `/users/rewardPoints/${userId}/course/${courseId}`
  );
  return data;
};
