import { regenBasic, regen } from "../utils/axios";
import { getUserId } from "../utils/jwt";

//Retail Clients
export const getRetailClients = async () => {
  const userId = getUserId();
  if (!userId) {
    return [];
  }

  const url = `/users/${userId}/retailClients`
  const response = await regenBasic.get(url);
  return response?.data;
};

export const getRetailClientOrders = async (clientId, year) => {
  const userId = getUserId();
  if (!userId) {
    return [];
  }

  const url = `/users/${userId}/retailClients/${clientId}/orders?year=${year}`
  const response = await regenBasic.get(url);
  return response?.data;
};


export const getRetailClientInfo = async (clientId) => {
  const userId = getUserId();
  if (!userId) {
    return [];
  }

  const url = `/users/${userId}/retailClients/${clientId}`
  const response = await regenBasic.get(url);
  return response?.data;
}

export const searchRetailClients = async (searchQuery) => {
  const url = `/client/search?search=${searchQuery}`
  const response = await regen.get(url);
  return response?.data;
};

export const createRetailClients = async (data) => {
  const response = await regen.post('/client', data);
  return response?.data;
};