import {
  useQuery,
  useQueryClient,
  useMutation,
  useInfiniteQuery,
} from "react-query";
import {
  searchProducts,
  getBundles,
  createBundle,
  deleteBundle,
  getBundleById,
  updateBundle,
  searchBundle,
  getBundleByToken,
  downloadBundleReport,
  getAdminBundleByToken,
} from "../services";
import { getUserId } from "../utils/jwt";
import { makeFileDownload } from "../utils";

export const useSearchProducts = (query) => {
  const userId = getUserId();
  return useQuery(
    ["productRecommendation", userId, query],
    () => searchProducts(query),
    {
      retry: 0,
      enabled: !!userId,
    }
  );
};

export const useGetBundles = () => {
  const userId = getUserId();
  return useInfiniteQuery(["bundles", userId], getBundles, {
    retry: 0,
    enabled: !!userId,
    keepPreviousData: true,
    getNextPageParam: (data) => {
      if (data) {
        return data.currentPage < data.totalPages
          ? data.currentPage + 1
          : undefined;
      }
    },
  });
};

export const useCreateBundle = (handleSuccess, handleError) => {
  const queryClient = useQueryClient();
  return useMutation("createBundle", createBundle, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["bundles"]);
      handleSuccess(data);
    },
    onError: (error) => {
      handleError(error?.message);
    },
  });
};

export const useDeleteBundle = (handleSuccess, handleError) => {
  const queryClient = useQueryClient();
  return useMutation("deleteBundle", deleteBundle, {
    onSuccess: () => {
      queryClient.invalidateQueries(["bundles"]);
      handleSuccess();
    },
    onError: (error) => {
      handleError(error?.message);
    },
  });
};

export const useGetBundleById = (bundleId) => {
  const userId = getUserId();
  return useQuery(
    ["bundleById", userId, bundleId],
    () => getBundleById(bundleId),
    {
      retry: 0,
      enabled: !!(userId && bundleId),
    }
  );
};

export const useUpdateBundle = (handleSuccess, handleError) => {
  const queryClient = useQueryClient();
  return useMutation("updateBundle", updateBundle, {
    onSuccess: () => {
      queryClient.invalidateQueries(["bundles"]);
      queryClient.invalidateQueries(["bundleById"]);
      handleSuccess();
    },
    onError: (error) => {
      handleError(error?.message);
    },
  });
};

export const useSearchBundle = (bundleName) => {
  const userId = getUserId();
  return useQuery(
    ["searchedBundle", userId, bundleName],
    () => searchBundle(bundleName),
    {
      retry: 0,
      enabled: Boolean(bundleName),
    }
  );
};

export const useGetBundleByToken = (token) => {
  const userId = getUserId();
  return useQuery(
    ["bundleByToken", userId, token],
    () => getBundleByToken(token),
    {
      retry: 0,
      enabled: !!(userId && token),
    }
  );
};

export const useGetBundleReport = (bundleId, bundleName) => {
  const userId = getUserId();
  return useQuery(
    ["bundleReport", userId, bundleId],
    () => downloadBundleReport(bundleId),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        getBundleReport(data, bundleName);
      },
    }
  );
};

const getBundleReport = (data, bundleName) => {
  const headingArr = [
    "Bundle Name",
    "Pro",
    "Retail User",
    "Order Status",
    "Order ID",
    "Created At",
    "Updated At",
  ];

  makeFileDownload(`${bundleName}.csv`, data, headingArr);
};

export const useGetAdminBundleByToken = (token) => {
  return useQuery(
    ["adminBundleByToken", token],
    () => getAdminBundleByToken(token),
    {
      retry: 0,
      enabled: !!token,
    }
  );
};
