import { regenBasic } from "../utils/axios";
import { getUserId } from "../utils/jwt";

//Favorites

export const getFavoriteProducts = async () => {
  const userId = getUserId();
  const { data } = await regenBasic.get(`/users/${userId}/favorite`);
  return data;
};

export const deleteFavoriteProducts = async (sku) => {
  const userId = getUserId();
  const { data } = await regenBasic.delete(`/users/${userId}/favorite/${sku}`);
  return data;
};

export const addFavoriteProducts = async (sku) => {
  const userId = getUserId();
  const { data } = await regenBasic.put(`/users/${userId}/favorite/${sku}`);
  return data;
};
