import { useQuery } from "react-query";
import { getCategories } from "../services/products";

function useCategory() {
  return useQuery("category", () => getCategories(), {
    refetchOnMount: false,
  });
}

export default useCategory;
