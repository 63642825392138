import { regenBasic, regen } from "../utils/axios";
import {
  getUserId,
  setRegenToken,
  setSalesChannel,
  setUserId,
  setUser,
  checkLogin,
  setHasImpersonate,
} from "../utils/jwt";
import { setAccountId_GA } from "../utils/GoogleAnalytics";

// ===========Get user ===========

export const loginUser = async (loginInfo) => {
  const { data } = await regenBasic.post(`/auth/users/login`, {
    ...loginInfo,
  });

  setAccountId_GA(data?.accountId);
  setRegenToken(data.jwt);
  setSalesChannel(data.site);
  setUserId(data.id);
  setUser(data);
  setHasImpersonate(false);
  return data;
};

export const getUserById = async (id) => {
  const { data } = await regenBasic.get(`/users/${id}`);
  setAccountId_GA(data?.accountId);
  setUser(data);
  return data;
};

// =========refresh jwt ============

export const refreshToken = async () => {
  const isLoggeedIn = checkLogin();
  if (isLoggeedIn) {
    const { data } = await regen.get(`/auth/users/jwt/refresh`);
    setRegenToken(data.jwt);
    return data;
  }
  return;
};

// ======= register retail user =====

export const registerRetail = async (user) => {
  const { data } = await regenBasic.post(`/users`, user);
  setRegenToken(data.jwt);
  setSalesChannel(data.user.site);
  setUserId(data.user.id);
  setUser(data.user);
  return data;
};

export const searchStoreLabel = async (storeLabel) => {
  const { data } = await regenBasic.get(`/users/storeLabel/${storeLabel}`);
  if (data?.hasRetailStore) {
    return data;
  } else {
    throw new Error("Retail store was not found.");
  }
};

export const searchStore = async (search) => {
  const { data } = await regenBasic.get(
    `/users/storeLabelSearch?search=${search}`
  );
  const { response } = data || {};

  if (response?.length > 0) {
    const retailStores = response?.filter((store) => store.hasRetailStore);
    if (retailStores?.length) {
      return retailStores;
    } else {
      throw new Error("Retail store was not found.");
    }
  } else {
    throw new Error("Retail store was not found.");
  }
};

// ======= request reset password =====

export const requestResetPassword = async (pastData) => {
  const { data } = await regenBasic.post("/users/forgotPassword", pastData);
  return data;
};

// ======= confirm reset password =====

export const confirmResetPasswordToken = async (token) => {
  const { data } = await regenBasic.get(`/users/validateToken/${token}`);
  return data;
};

// ======= reset password =====

export const resetPassword = async (pastData) => {
  const { token, password } = pastData;
  const { data } = await regenBasic.post(`/users/resetPassword/${token}`, {
    password,
  });
  return data;
};

// Reset Account password in my-account
export const resetMyAccountPassword = async (password) => {
  const userId = getUserId();
  const { data } = await regenBasic.post(`/users/resetPassword/${userId}`, {
    password,
    isToken: false,
  });
  return data;
};

// update user phone number
export const updateUserPhoneNumber = async (props) => {
  const userId = getUserId();
  const { data } = await regen.put(`/users/${userId}/phone`, { ...props });
  return data;
};

// Users Location

export const getUsersLocation = async () => {
  const userId = getUserId();
  const { data } = await regenBasic.get(`/users/${userId}/locations`);
  return data;
};

export const editUsersLocation = async (location) => {
  const { locationId, ...newLocation } = location;
  const userId = getUserId();
  const { data } = await regenBasic.put(
    `/users/${userId}/locations/${locationId}`,
    newLocation
  );
  return data;
};

export const addUsersLocation = async (location) => {
  const userId = getUserId();
  const { data } = await regenBasic.post(
    `/users/${userId}/locations`,
    location
  );
  return data;
};

export const deleteUsersLocation = async (locationId) => {
  const userId = getUserId();
  const { data } = await regenBasic.delete(
    `/users/${userId}/locations/${locationId}`
  );
  return data;
};

// ======= Accept Terms and conditions =====

export const acceptTermsAndCondition = async (userId) => {
  const { data } = await regen.post(`/users/${userId}/acceptTermsAndCondition`);
  return data;
};

//=========Log Out User ===================

export const logOutUser = async (userId) => {
  const { data } = await regenBasic.put(`/users/${userId}/logOut`);
  return data;
};

//=========Update account name ===================

export const updateAccountName = async (accountName) => {
  const userId = getUserId();
  const { data } = await regen.put(`/users/${userId}/accountName`, accountName);
  setUser(data);
  return data;
};
