import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUpdateCart } from "../../hooks/useManageCart";
import { Box, IconButton, TextField, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import css from "../../styles/SkuInfo.module.css";
import OutOfStock from "./OutOfStock";
import { addToCart_GA, viewCart_GA } from "../../utils/GoogleAnalytics";
import { GlyMedSnackbarState } from "../../atoms/globalAtom";
import { useRecoilState } from "recoil";
import { getHasImpersonate, checkPermission } from "../../utils/jwt";
import { PERMISSSIONS } from "../checkout/Constants";

const CartActions = ({ selectedSku, cart }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const hasImpersonate = getHasImpersonate();
  const hasEditPerm = checkPermission(PERMISSSIONS.EDIT_ORDER_ITEM);
  const disableEdit = hasImpersonate && !hasEditPerm;

  const { sku, inventory } = selectedSku || {};
  const [qty, setQty] = useState(1);
  const [isInCart, setIsInCart] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [, setSnackbarState] = useRecoilState(GlyMedSnackbarState);

  const handleChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    value !== "" ? setQty(parseInt(value)) : setQty(value);
  };

  const handleBlur = (e) => {
    e.preventDefault();
    const cartData = cart.find((item) => item.sku === selectedSku.sku);
    const quantity = cartData?.quantity;
    if (qty === quantity) return;
    if (qty === "") return quantity ? setQty(quantity) : setQty(1);
    if (qty <= inventory)
      return handleAddOrRemove([{ sku, quantity: qty }], []);
    if (qty > inventory) {
      if (quantity !== inventory)
        handleAddOrRemove([{ sku, quantity: inventory }], []);
      return setQty(inventory);
    }
  };

  const handleDecrement = () => {
    const newQty = qty - 1;
    if (newQty <= 0) {
      handleAddOrRemove([], [sku]);
      setQty(1);
    } else {
      handleAddOrRemove([{ sku: sku, quantity: newQty }], []);
      setQty(newQty);
    }
  };

  const handleIncrement = () => {
    const incBy = 1;
    addToCart_GA({ id: sku, quantity: incBy });
    if (qty <= inventory) {
      if (isInCart) {
        handleAddOrRemove([{ sku, quantity: qty + incBy }], []);
        setQty(qty + incBy);
      } else {
        handleAddOrRemove([{ sku, quantity: qty }], []);
      }
    }
  };

  const handleQtyChange = useCallback(() => {
    if (!cart || cart?.length === 0) {
      setQty(1);
      setIsInCart(false);
    } else {
      const cartData = cart.find((item) => item.sku === selectedSku.sku);
      if (cartData) {
        setQty(cartData?.quantity);
        setIsInCart(true);
      } else {
        setQty(1);
        setIsInCart(false);
      }
    }
  }, [selectedSku, cart]);

  useEffect(() => {
    handleQtyChange();
  }, [handleQtyChange]);

  const { mutate, isLoading, isError, isSuccess, error } = useUpdateCart();

  const handleAlertMsg = (cartList, deleteList) => {
    let message;
    if (!isInCart) message = "Item added successfully";
    if (deleteList.length !== 0) message = "Item removed successfully";
    if (isInCart && cartList.length !== 0) {
      cartList[0].quantity === 0
        ? (message = "Item removed successfully")
        : (message = "Item updated successfully");
    }
    setAlertMsg(message);
  };

  const handleAddOrRemove = (cartList, deleteList) => {
    handleAlertMsg(cartList, deleteList);
    mutate({
      items: cartList,
      itemsRemoved: deleteList,
      rewardPoints: 0,
      couponCode: "",
      shipVia: "",
      shippingRate: "",
      shipName: "",
      discount: 0,
      isPercent: false,
    });
  };

  const handleNavigateToCart = () => {
    viewCart_GA(null);
    navigate(`/cart`, { state: { backgroundLocation: location } });
  };

  const disableAddBtn = () => {
    const cartData = cart?.find((item) => item.sku === selectedSku.sku);
    const quantity = cartData?.quantity;
    if (disableEdit) return true;
    if (inventory <= 0) return true;
    if (qty > inventory) return true;
    if (quantity === inventory) return true;
    return false;
  };

  const handleAlert = useCallback(() => {
    if (isSuccess) {
      setSnackbarState({
        message: alertMsg,
        severity: "success",
        open: true,
      });
    }
    if (isError) {
      setSnackbarState({
        message: error?.message,
        severity: "error",
        open: true,
      });
    }
  }, [isError, isSuccess, error, alertMsg, setSnackbarState]);

  useEffect(() => {
    handleAlert();
  }, [handleAlert]);

  return (
    <Box className={css.actionsContainer}>
      <IconButton
        disabled={inventory <= 0 || (qty === 1 && !isInCart) || disableEdit}
        sx={{ height: "auto" }}
        onClick={handleDecrement}
      >
        {qty === 1 && isInCart ? (
          <DeleteIcon color="secondary" />
        ) : (
          <RemoveIcon
            color={
              inventory <= 0 || (qty === 1 && !isInCart)
                ? "inherit"
                : "secondary"
            }
          />
        )}
      </IconButton>
      <form onSubmit={handleBlur}>
        <TextField
          disabled={inventory <= 0 || isLoading || disableEdit}
          className={css.priceInput}
          value={qty}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ style: { textAlign: "center" } }}
          InputProps={{
            sx: { width: 56, height: 48 },
          }}
          variant="outlined"
        />
      </form>
      <IconButton
        disabled={disableAddBtn()}
        sx={{ height: "auto" }}
        onClick={handleIncrement}
      >
        <AddIcon color={disableAddBtn() ? "inherit" : "secondary"} />
      </IconButton>
      {inventory > 0 ? (
        isInCart ? (
          <Button
            onClick={handleNavigateToCart}
            className={css.addBtn}
            variant="btn-primary-outlined"
          >
            In Your Cart
          </Button>
        ) : (
          <Button
            onClick={handleIncrement}
            className={css.addBtn}
            variant="btn-secondary"
            disabled={disableEdit}
          >
            Add To Cart
          </Button>
        )
      ) : (
        <OutOfStock selectedSku={selectedSku} />
      )}
    </Box>
  );
};

export default CartActions;
