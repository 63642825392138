import { styled } from "@mui/material/styles";

export const StyledHero = styled("h1", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme }) => ({
  fontSize: "2.375em",
  color: "var(--black)",
  fontFamily: "Presicav-Light",
  lineHeight: "40px",
  fontWeight: 300,

  [theme.breakpoints.up("md")]: {
    fontSize: "3.25em",
    lineHeight: "54px",
  },
}));

export const StyledHeroH2 = styled("h2", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme }) => ({
  fontSize: "1.75em",
  color: "var(--black)",
  fontFamily: "Presicav-Light",
  lineHeight: "32px",

  [theme.breakpoints.up("md")]: {
    fontSize: "2.125em",
    lineHeight: "38px",
  },
}));

export const StyledBodyLight = styled("p", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme }) => ({
  fontSize: "1em",
  color: "var(--black)",
  fontFamily: "Nimbus-Sans-Light",
  lineHeight: "20px",

  [theme.breakpoints.up("md")]: {
    fontSize: "1em",
  },
}));

export const StyledBodyRegular = styled("p", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme }) => ({
  fontSize: "1em",
  color: "var(--black)",
  fontFamily: "Nimbus-Sans-Regular",

  [theme.breakpoints.up("md")]: {
    fontSize: "1em",
  },
}));

export const StyledBodyBold = styled("p", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme }) => ({
  fontSize: "1em",
  color: "var(--black)",
  fontFamily: "Nimbus-Sans-Regular",
  fontWeight: 600,
  lineHeight: "20px",

  [theme.breakpoints.up("md")]: {
    fontSize: "1em",
  },
}));

export const StyledLabelRegular = styled("p", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme }) => ({
  fontSize: "0.875em",
  color: "var(--black)",
  fontFamily: "Nimbus-Sans-Regular",
  lineHeight: "18px",
}));

export const StyledLabelBold = styled("p", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme }) => ({
  fontSize: "0.875em",
  color: "var(--black)",
  fontFamily: "Nimbus-Sans-Regular",
  fontWeight: 600,
  lineHeight: "18px",
}));

export const StyledLabelLight = styled("p", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme }) => ({
  fontSize: "0.875em",
  color: "var(--black)",
  fontFamily: "Nimbus-Sans-Light",
  fontWeight: 300,
  lineHeight: "18px",
}));

export const StyledLabelCaps = styled("p", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme }) => ({
  fontSize: "0.875em",
  color: "var(--black)",
  fontFamily: "Nimbus-Sans-Regular",
  lineHeight: "16px",
  textTransform: "uppercase",
}));


export const StyledButton = styled('button', {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) =>
    prop !== 'color' && prop !== 'variant' && prop !== 'sx',
})(({ theme }) => ({
  fontSize: "1em",
  lineHeight: "20px",
  fontFamily: "Nimbus-Sans-Regular",
}))

export const StyledList = styled("li", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme }) => ({
  fontSize: "1em",
  color: "var(--black)",
  fontFamily: "Nimbus-Sans-Light",
  lineHeight: "20px",
  listStyle: "disc",
}));

export const StyledPRegular = styled("p", {
  shouldForwardProp: (prop) => prop !== "sx",
})(() => ({
  fontSize: "1em",
  fontFamily: "Nimbus-Sans-Regular",
  lineHeight: "1.25em",
}));