
const ProductDetailsHeader = ({ data }) => {
  const { name, disabled } = data || {};
  return (
    <>
      <h1>
        {name} {disabled && <b>*</b>}
      </h1>
    </>
  );
};

export default ProductDetailsHeader;