import { v4 as uuid } from "uuid";

const getDeviceId = () => {
  const deviceId = localStorage.getItem("deviceId");
  if (!deviceId) {
      const newDeviceId = uuid();
      localStorage.setItem("deviceId", newDeviceId);
      return newDeviceId;
  }
  return deviceId;
};

export default getDeviceId;
