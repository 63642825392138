import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import css from "../../styles_web/EmptyCart.module.css";
import CartHeaedr from "./CartHeader";

const EmptyCart = ({ handleBack }) => {

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/products");
  }

  return (
    <>
      <CartHeaedr handleBackdropClick={handleBack} />
      <Box className={css.container}>
        <h3>Your cart is empty</h3>
        <Button className={css.btn} onClick={handleNavigation} variant="btn-secondary">Browse Products</Button>
      </Box>
    </>

  );
};

export default EmptyCart;
