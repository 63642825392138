import React, {  } from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography";
import { useCookies } from "react-cookie";
import { cookieNotice } from "../constants/mainSiteNavigation.js";
//import css from "../styles_web/CookieNotice.module.css";


const CookieNotice = () => {
  const [cookies, setCookie] = useCookies(['using-cookies-ack']);
  const ackCookies = () => {
    //save acknowledgement for 180 days.
    setCookie("using-cookies-ack", "true", { maxAge: 15552000 });
  }
  return (
    <CookieNoticeDrawer 
      isOpen={!(cookies['using-cookies-ack'])} 
      onAccept={ackCookies} 
      title={cookieNotice.title} 
      description={cookieNotice.description} 
    />
  )
};

const CookieNoticeDrawer = ({
  title,
  description,
  isOpen,
  onAccept,
}) => {
 
  return (
    <Drawer open={isOpen} anchor="bottom" transitionDuration={500}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100vw",
            flexDirection: "row",
            padding: "20px",
            justifyContent: "space-between",
            gridGap: "20px"
          }}>
          <div>
            <Typography variant="h6">
              {title}
            </Typography>
            <Typography variant="body1">
              {description}
            </Typography>
          </div>
          <Box 
            sx={{
            
            }}>
          <Button style={{ display: "block", width: "120px" }} variant="contained" onClick={onAccept}>I Accept</Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

CookieNoticeDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
 
};

export default CookieNotice;
