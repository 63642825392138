export const getRewardInfo = (rewards) => {
  const currentTier = rewards.currentTier?.name || "Pro";
  let nextTier = "Bronze";
  switch (currentTier) {
    case "Bronze":
      nextTier = "Silver";
      break;
    case "Silver":
      nextTier = "Gold";
      break;
    case "Gold":
      nextTier = "Platinum";
      break;
    case "Platinum":
      nextTier = "Diamond";
      break;
    case "Pro":
    default:
      nextTier = "Bronze";
      break;
  }
  const nextTierInfo = rewards?.otherTiers?.find((t) => t.name === nextTier);
  const spendNeeded = nextTierInfo?.minSpend - rewards?.totalSpend;

  return {
    tier: currentTier,
    tierLabel: currentTier,
    points: rewards.availablePoints,
    info: rewards.statusValidThruMessage,
    nextTier: nextTier,
    nextTierLabel: nextTier,
    nextTierSpend: spendNeeded,
    expiring: rewards?.expiring?.message,
    expiringDuration: rewards?.expiring?.expiringDuration,
    expiringPoints: rewards?.expiring?.expiringPoints,
    currentTierMinSpend: rewards?.currentTier?.minSpend ?? 0,
    nextTierMinSpend: nextTierInfo?.minSpend ?? 0,
    isFirstTier: !rewards.currentTier?.name,
    isLastTier:
      rewards?.otherTiers?.[rewards?.otherTiers?.length - 1]?.name ===
      currentTier,
    tierProgressPercent: rewards?.currentTier
      ? (1 -
          (rewards.currentTier?.maxSpend - rewards?.totalSpend) /
            (rewards?.currentTier?.maxSpend - rewards?.currentTier.minSpend)) *
        100
      : (100 * rewards?.totalSpend) / (nextTierInfo?.minSpend || 1),
  };
};

export const getRewardColor = (tier) => {
  let tierColor = "--pro-tier";
  switch (tier) {
    case "Bronze":
      tierColor = "--bronze-tier";
      break;
    case "Silver":
      tierColor = "--silver-tier";
      break;
    case "Gold":
      tierColor = "--gold-tier";
      break;
    case "Platinum":
      tierColor = "--platinum-tier";
      break;
    case "Diamond":
      tierColor = "--diamond-tier";
      break;
    case "Pro":
    default:
      tierColor = "--pro-tier";
      break;
  }

  return tierColor;
};
