import { regen, regenBasic } from "../utils/axios";
import { getUserId } from "../utils/jwt";

//Get Address
export const getShippingAddress = async (userId) => {
  const { data } = await regen.get(`/users/address/${userId}`);
  return data;
};

//Add New Address

export const addNewAddress = async (address) => {
  const userId = getUserId();
  const { data } = await regen.post(`/users/address/${userId}`, address);
  return data;
};

//Edit Address
export const editAddress = async (address) => {
  const userId = getUserId();
  const { data } = await regen.put(`/users/address/${userId}`, address);
  return data;
};

//make Address default
export const makeAddressDefault = async (addressId) => {
  const userId = getUserId();
  const { data } = await regen.put(
    `/users/defaultAddress/${userId}`,
    addressId
  );
  return data;
};

//make Address Main
export const makeAddressMain = async (payload) => {
  const userId = getUserId();
  const { data } = await regen.put(`/users/defaultAddress/${userId}`, payload);
  return data;
};

//Delete Address
export const deleteAddress = async (addressId) => {
  const userId = getUserId();
  const { data } = await regen.delete(`/users/${userId}/address/${addressId}`);
  return data;
};

//Get Shipping Option
export const getShippingOption = async (userId) => {
  const { data } = await regenBasic.get(`/shippingMethods/${userId}`);
  return data;
};
