export const getAnimation = (i) => {
  const index = i ? i : 0;
  return {
    animation: `scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)${(index % 9) * 100
      }ms both`,
  };
};

export const isEmpty = (obj) => Object.keys(obj).length === 0;

export const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0;
};

export const getFormattedPrice = (price, precision) => {
  if (price) {
    return numberWithCommas(price.toFixed(precision));
  } else {
    let x = 0;
    return x.toFixed(precision);
  }
};

export const displayFormattedItemPrice = (item, precision) => {
  if (item.quantity === 1) {
    if (item?.isFree) {
      return "FREE";
    }
    return `$${getFormattedPrice(item?.totalWithoutTax, precision)}`;
  } else {
    return (
      <>
        <b>{`$${getFormattedPrice(item?.totalWithoutTax, precision)}\u00a0`}</b>
        {`($${getFormattedPrice(item?.netPriceForUI ? item?.netPriceForUI : item?.netPrice, precision)} each)`}
      </>
    );
  }
}

export const getFormattedPercent = (percent) => {
  let wholePercent = 0;
  if (percent) {
    wholePercent = Math.round(percent * 100);
  }

  return `${wholePercent}%`;
};

function roundFunc(method, number, precision) {
  if (typeof number !== "number") {
    throw new TypeError("Expected value to be a number");
  }

  if (precision === Infinity) {
    return number;
  }

  if (!Number.isInteger(precision)) {
    throw new TypeError("Expected precision to be an integer");
  }

  const isRoundingAndNegative = method === "round" && number < 0;
  if (isRoundingAndNegative) {
    number = Math.abs(number);
  }

  const power = 10 ** precision;

  let result = Math[method](Number((number * power).toPrecision(15))) / power;

  if (isRoundingAndNegative) {
    result = -result;
  }

  return result;
}

const round = roundFunc.bind(undefined, "round");
const up = roundFunc.bind(undefined, "ceil");
const down = roundFunc.bind(undefined, "floor");

export { round, up, down };

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return phoneNumberString;
};

export const validatePhoneInput = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  return currentValue;
};

export const normalizePhoneInput = (value) => {
  if (!value) return value;
  const cvLength = value.length;
  if (cvLength) {
    if (cvLength < 4) return value;
    if (cvLength < 7) return `${value.slice(0, 3)}-${value.slice(3)}`;
    return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;
  }
};

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    return true;
  }
  return regex.test(email) === true;
};

export const validateNotifyEmail = (email) => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/; //eslint-disable-line
  if (!email) {
    return true;
  }
  return regex.test(email) === true;
};

export const copyTextToClipboard = async (text) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  }
};

const csvEscapeQuotes = (instr) => {
  if (instr && instr.length > 0 && instr.indexOf('"') > -1) {
    return instr.replace(/"/g, '""""');
  } else {
    return instr;
  }
};

export const makeFileDownload = (fileNameWithExtention, data, headings) => {
  if (data) {
    let csvData = "data:application/octet-stream,";
    let numFields = headings.length;
    let fields = [];
    headings.forEach((item, index) => {
      let heading = item.trim();
      if (heading !== "") {
        fields.push(heading);
        csvData += encodeURIComponent(heading); // Encode the heading to include spaces
        if (index < numFields - 1) {
          csvData += "%2C";
        } else {
          csvData += "%0A";
        }
      }
    });
    let fieldsLength = fields.length;
    data.forEach((item, index) => {
      fields.forEach((field, fieldIndex) => {
        if (data[index][field] && typeof data[index][field] !== "boolean") {
          csvData +=
            '"=""' +
            encodeURIComponent(csvEscapeQuotes(data[index][field])) +
            '"""';
        } else if (typeof data[index][field] === "boolean") {
          csvData += data[index][field] ? "True" : "False";
        } else {
          csvData += "";
        }
        if (fieldIndex < fieldsLength - 1) {
          csvData += "%2C";
        } else {
          csvData += "%0A";
        }
      });
    });

    const downloadLink = document.createElement("a");
    downloadLink.href = csvData;
    downloadLink.download = fileNameWithExtention;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
};

export const checkPassword = (value) => {
  const isValidPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(value);
  return isValidPassword;
};


export const haversineDistance = (lat1, lng1, lat2, lng2) => {
  if (lat1 && lng1 && lat2 && lng2) {
    const toRadians = (degree) => (degree * Math.PI) / 180;
    const R = 3958.8; // Earth's radius in miles

    const dLat = toRadians(lat2 - lat1);
    const dLng = toRadians(lng2 - lng1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in miles
  }
  return null;
}