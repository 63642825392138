export const CATEGORIES = "Categories";

export const CATEGORY = "category";

export const INDEPENDENT_RETAILER = "Find an independent retailer";

export const FILTER_TEXT = "Filter";

export const BACK_TO_PRODUCTS = "BACK";

export const ADD_TO_CART = "Add To Cart";

export const ADD_ALL_TO_CART = "Add All to Cart";

export const DELETE_ALL_FROM_CART = "Remove All From Cart";

export const RESET = "Reset";

export const APPLY_FILTER = "Apply Filter";

export const ITEM_ADD_SUCCESS = "Your item is successfully added to cart!";
export const ITEMS_ADD_SUCCESS = "Your items are successfully added to cart!";

export const ITEM_DELETE_SUCCESS =
  "All items of this list are removed from cart";

export const ITEM_DELETE_SUCCESS_SINGLE = "Item is removed from cart";

export const LOGIN_MESSAGE = "Please login to be able to shop!";

export const SIGNIN = "Sign In";

export const SIZE = "Size";
export const PRICE = "Price";
export const QUANTITY = "Qty";
export const IN_STOCK = "In Stock";
export const OUT_OF_STOCK = "Out of Stock";
export const NOTIFY_ME = "Notify Me";
export const NOTIFICATION_HEADER = "GET NOTIFIED WHEN IT'S BACK IN STOCK";
export const EMAIL_ADDRESS = "Email address";
export const NOTIFICATION_MESSAGE = "Notify me about new products too";
export const NOTIFICATION_HINT =
  "You are giving GLYMED permission to contact you via email. We will never share your email address with anyone else";
export const SUBSCRIBE_SUCCESS =
  "You have been successfully subscribed to product ";
export const SUBSCRIBE_SUCCESS_NEW =
  "You have been successfully subscribed to new products ";
export const UNSUBSCRIBE_SUCCESS = "You have Unsubscribed";
export const RESUBSCRIBE_SUCCESS = "You have Resubscribed";
export const RESUBSCRIBE_MESSAGE =
  "To start receiving product notifications again, you can resubscribe at any time by either clicking the button below or going to the product’s information page on our website";
export const RESUBSCRIBE = "Resubscribe";
export const NO_PRODUCTS =
  "There are no products that match your selected criteria";
export const CLEAR_FILTERS = "Clear Filters";

export const PRODUCT_INFO = {
  recommendedFor: "Recommended for",
  healthyStep: "Healthy Step",
  benefits: "Benefits",
  concerns: "Concerns",
  skinTypes: "Skin Types",
  allergens: "Allergens",
  quantity: "Qty",
  directions: "Directions",
  faq: "FAQ",
  ingredients: "Ingredients",
};
