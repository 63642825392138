import { useParams, Navigate, Route, useLocation } from "react-router-dom";
import { Fragment } from "react";
const OLD_BASE_ROUTES = ["web", "home", "sch"];

const ProductRoute = () => {
  const { search } = useLocation();
  const validSku = new RegExp(/\d+|GM\w+|GRX\w+/gm);
  const { sku } = useParams();

  if (sku && validSku.test(sku)) {
    return <Navigate to={`/products/${sku}${search}`} />;
  }
  return <Navigate to={ `/products${search}` } />;
};

const CourseDetailRoute = () => {
  const { search } = useLocation();
  const { courseId } = useParams();
  if (courseId.startsWith("HAE")) {
    return <Navigate to={`/calendar/hae/${courseId}${search}`} />;
  }

  console.log("Routing to calendar")
  return <Navigate to={`/calendar${search}`} />;
};


const BlogsRoute = () => {
  const { search } = useLocation();
  const { blogPostId } = useParams();

  if (blogPostId) {
    console.log("BlogId found");
    return <Navigate to={`/blogs/${blogPostId}${search}`} />;
  }

  return <Navigate to={`/blogs${search}`} />;
}

const ProcotolsRoute = () => {
  const { search } = useLocation();
  const { protocolId } = useParams();

  if (protocolId) {
    return <Navigate to={`/protocols/${protocolId}${search}`} />;
  }

  return <Navigate to={`/protocols${search}`} />;
}

const WebinarsRoute = () => {
  const { search } = useLocation();
  const { webinarId } = useParams();

  if (webinarId) {
    return <Navigate to={`/webinars/${webinarId}${search}`} />;
  }

  return <Navigate to={`/webinars${search}`} />;
}

const SchoolEducationRoute = () => {
  const { search } = useLocation();
  const { topic, section } = useParams();

  if (topic) {
    if (section) {
      return <Navigate to={`/school/education/${topic}/${section}${search}`} />;
    }

    return <Navigate to={`/school/education/${topic}${search}`} />;
  }

  return <Navigate to={`/school/education/${search}`} />;
}

const ProOrdersRoute = () => {

  return OrdersRoute("b2b");
}

const RetailOrdersRoute = () => {
  return OrdersRoute("retail");
}

const SchoolOrdersRoute = () => {
  return OrdersRoute("school");
}

const OrdersRoute = (path) => {
  const { search } = useLocation();

  // We are displaying orders using the shortId, old URLs will have the long order GUID,
  // so we'll direct users to their my-orders page.
  return <Navigate to={`/${path}/my-account/my-orders${search}`} />;
}

const StoreRetailOrdersRoute = () => {
  const { search } = useLocation();

  // We are displaying orders using the shortId, old URLs will have the long order GUID,
  // so we'll direct users to their my-orders page.
  return <Navigate to={`/b2b/my-store/retail-orders${search}`} />;
}

const ResetPasswordRoute = () => {
  const { search } = useLocation();
  const { token } = useParams();

  if (token) {
    return <Navigate to={`/resetPassword/${token}${search}`} />;
  }

  return <Navigate to={`/resetPassword${search}`} />;
}

export const OldBaseRoute = () => {
  const { search } = useLocation();
  return (
    <>
      <Route path="regen_stores" element={<Navigate to={`/login${search}`} />} />
      { OldRetailRoute() }
      { OldSchoolRoute() }
      {OLD_BASE_ROUTES.map((path) => {
        return (
          <Fragment key={path}>
            <Route path={`${path}/classDetails/:courseId`} element={<CourseDetailRoute />} />
            <Route path={`${path}/page/blog`} element={<Navigate to={`/blogs${search}`} />} />
            <Route path={`${path}/page/blog/:blogPostId`} element={<BlogsRoute />} />
            <Route path={`${path}/products/ourline/*`} element={<Navigate to={`/products${search}`} />} />
            <Route path={`${path}/products/:sku`} element={<ProductRoute />} />
            <Route path={`${path}/cart`} element={<Navigate to={`/cart${search}`} />} />
            <Route path={`${path}/login`} element={<Navigate to={`/login${search}`} />} />
            <Route path={`${path}/protocols`} element={<Navigate to={`/protocols${search}`} />} />
            <Route path={`${path}/protocols/:protocolId`} element={<ProcotolsRoute />} />
            <Route path={`${path}/locations`} element={<Navigate to={`/locations${search}`} />} />
            <Route path={`${path}/resource_library`} element={<Navigate to={`/resources${search}`} />} />
            <Route path={`${path}/register`} element={<Navigate to={`/register-retail${search}`} />} />
            <Route path={`${path}/education`} element={<Navigate to={`/education${search}` } />} />
            <Route path={`${path}/page/calendar`} element={<Navigate to={`/calendar${search}`} />} />
            <Route path={`${path}/becomePro`} element={<Navigate to={`/become-pro${search}`} />} />
            <Route path={`${path}/webinars`} element={<Navigate to={`/webinars${search}` } />} />
            <Route path={`${path}/webinars/:webinarId`} element={<WebinarsRoute />} />
            <Route path={`${path}/hs/gmp_professional_form`} element={<Navigate to={`/become-pro${search}`} />} />
            <Route path={`${path}/hs/gmp_professional_form/modal`} element={<Navigate to={`/become-pro/gmp_professional_form${search}`} />} />
            <Route path={`${path}/becomePro/gmp_professional_form`} element={<Navigate to={`/become-pro${search}`} />} />
            <Route path={`${path}/becomePro/gmp_professional_form/modal`} element={<Navigate to={`/become-pro/gmp_professional_form${search}`} />} />
            <Route path={`${path}/forgotPassword`} element={<Navigate to={`/forgot-password${search}`} />} />
            <Route path={`${path}/page/christine`} element={<Navigate to={`/christine${search}`} />} />
            <Route path={`${path}/*`} element={<Navigate to={`/${search}`} />} />
          </Fragment>
        );
      })
      }
    </>
  );
};

export const OldProRoute = () => {
  const { search } = useLocation();
  return (
    <Fragment>
      <Route path="index" element={<Navigate to={`/${search}`} />} />
      <Route path="products/:sku" element={<ProductRoute />} />
      <Route path="products/ourline/*" element={<Navigate to={`/products${search}`} />} />
      <Route path="dashboard/profile" element={<Navigate to={`/b2b/my-account/profile${search}`} />} />
      <Route path="dashboard/orders" element={<ProOrdersRoute />} />
      <Route path="dashboard/orders/:orderId" element={<ProOrdersRoute />} />
      <Route path="dashboard/rewards" element={<Navigate to={`/b2b/my-account/regen-rewards${search}`} />} />
      <Route path="dashboard/favorites" element={<Navigate to={`/b2b/my-account/favorites${search}`} />} />
      <Route path="mystore/info" element={<Navigate to={`/b2b/my-store/info${search}`} />} />
      <Route path="mystore/clients" element={<Navigate to={`/b2b/my-store/client${search}`} />} />
      <Route path="mystore/retailOrders" element={<StoreRetailOrdersRoute />} />
      <Route path="mystore/retailOrders/:orderId" element={<StoreRetailOrdersRoute />} />
      <Route path="mystore/retailTopProducts" element={<Navigate to={`/b2b/my-store/top-products${search}`} />} />
      <Route path="features/for_men_oct_2022" element={<Navigate to={`/feature/for_men_oct_2022${search}`} />} />
      <Route path={`forgotPassword`} element={<Navigate to={`/forgot-password${search}`} />} />
      <Route path="resetPassword" element={<ResetPasswordRoute />} />
      <Route path="resetPassword/:token" element={<ResetPasswordRoute />} />
      <Route path="createPassword" element={<ResetPasswordRoute />} />
      <Route path="createPassword/:token" element={<ResetPasswordRoute />} />
    </Fragment>
  );
};

export const OldRetailRoute = () => {
  const { search } = useLocation();
  const path = "home";
  return (
    <Fragment>
      <Route path={`${path}/index`} element={<Navigate to={`/${search}`} />} />
      <Route path={`${path}/products/:sku`} element={<ProductRoute />} />
      <Route path={`${path}/products/ourline/*`} element={<Navigate to={`/products${search}`} />} />
      <Route path={`${path}/dashboard/profile`} element={<Navigate to={`/retail/my-account/profile${search}`} />} />
      <Route path={`${path}/dashboard/orders`} element={<RetailOrdersRoute />} />
      <Route path={`${path}/dashboard/orders/:orderId`} element={<RetailOrdersRoute />} />
      <Route path={`${path}/dashboard/favorites`} element={<Navigate to={`/retail/my-account/favorites${search}`} />} />
      <Route paht={`${path}/features/prepping_for_peel_season_oct_2022`} element={<Navigate to={`/feature/prepping_for_peel_season_oct_2022${search}`} />} />
      <Route path={`${path}/forgotPassword`} element={<Navigate to={`/forgot-password${search}`} />} />
      <Route path={`${path}/resetPassword`} element={<ResetPasswordRoute />} />
      <Route path={`${path}/resetPassword/:token`} element={<ResetPasswordRoute />} />
      <Route path={`${path}/createPassword`} element={<ResetPasswordRoute />} />
      <Route path={`${path}/createPassword/:token`} element={<ResetPasswordRoute />} />
    </Fragment>
  );
};

export const OldSchoolRoute = () => {
  const { search } = useLocation();
  const path = "sch";
  return (
    <Fragment>
      <Route path={`${path}/index`} element={<Navigate to={`/${search}`} />} />
      <Route path={`${path}/products/:sku`} element={<ProductRoute />} />
      <Route path={`${path}/products/ourline/*`} element={<Navigate to={`/products${search}`} />} />
      <Route path={`${path}/dashboard/profile`} element={<Navigate to={`/school/my-account/profile${search}`} />} />
      <Route path={`${path}/dashboard/orders`} element={<SchoolOrdersRoute />} />
      <Route path={`${path}/dashboard/orders/:orderId`} element={<SchoolOrdersRoute />} />
      <Route path={`${path}/dashboard/favorites`} element={<Navigate to={`/school/my-account/favorites${search}`} />} />
      <Route path={`${path}/education`} element={<SchoolEducationRoute />} />
      <Route path={`${path}/education/:topic`} element={<SchoolEducationRoute />} />
      <Route path={`${path}/education/:topic/:section`} element={<SchoolEducationRoute />} />
      <Route path={`${path}/forgotPassword`} element={<Navigate to={`/forgot-password${search}`} />} />
      <Route path={`${path}/resetPassword`} element={<ResetPasswordRoute />} />
      <Route path={`${path}/resetPassword/:token`} element={<ResetPasswordRoute />} />
      <Route path={`${path}/createPassword`} element={<ResetPasswordRoute />} />
      <Route path={`${path}/createPassword/:token`} element={<ResetPasswordRoute />} />
    </Fragment>
  );
};