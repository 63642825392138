const version = process.env.REACT_APP_RELEASE_VERSION;

const Image = (props) => {
  const {src, alt, ...remainingProps } = props;
  if (typeof src === 'string' && src.startsWith('http') && !props.noCache) {
    const url = new URL(src);
    
    // Check if the URL already has query parameters
    if (url.search) {
      url.search += `&v=${version}`;
    } else {
      url.search = `?v=${version}`;
    }
    
    return <img src={url.toString()}  alt={alt} {...remainingProps} />;
  }
  
  // If source is not a URL, render the image directly
  return <img src={src} alt={alt} {...remainingProps} />;
}

export default Image;
