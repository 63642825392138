import { useInfiniteQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { getProducts } from "../services";

function useProducts() {
  const location = useLocation();
  const filterString = location.search;
  const isBackground =
    location?.state?.backgroundLocation?.pathname === "/products";
  return useInfiniteQuery(
    ["products", filterString],

    getProducts(filterString),
    {
      enabled: !isBackground,
      retry: 0,
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage;
      },
      refetchOnMount: false,
    }
  );
}

export default useProducts;
