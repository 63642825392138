import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CouponHintBuyTogether = ({ meta }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`/products/${meta.sku}`);
  };

  return (
    <Box>
      Add{" "}
      <b style={{ cursor: "pointer" }} onClick={handleNavigation}>
        {meta.productName}
      </b>{" "}
      to qualify for an extra discount.
    </Box>
  );
};

export default CouponHintBuyTogether;
