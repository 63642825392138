
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { validateEmail } from "../utils";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useStayConnected } from "../hooks/useStayConnected";
import { useState } from "react";
import { Box, Grid, Button } from "@mui/material";



const StayConnected = () => {
  const [severity, setSeverity] = useState("success");
  const [msg, setMsg] = useState("");
  const [professional, setProfessional] = useState("");
  const [data, setData] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleProfessionalChange = (e) => {
    setProfessional(e.target.value);
  };

  const handleSuccess = () => {
    setSeverity("success");
    setMsg("Thank you for subscribing!");
    setProfessional("")
    setData({
      email: "",
      firstName: "",
      lastName: "",
    });
  };

  const handleError = (err) => {
    setMsg(err);
    setSeverity("error");
  };

  const handleReset = () => {
    setMsg("");
    setSeverity("");
  };

  const { mutate } = useStayConnected(handleSuccess, handleError);

  const { email, firstName, lastName } = data;
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = [
      {
        name: "email",
        value: email,
      },
      {
        name: "firstname",
        value: firstName,
      },
      {
        name: "lastname",
        value: lastName,
      },
      {
        name: "are_you_already_a_glymed_plus_professional_",
        value: professional,
      },
    ];
    mutate(data);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputLabel shrink={false} sx={{ color: "var(--black)" }}>
            First Name
          </InputLabel>
          <TextField
            size="small"
            name="firstName"
            type="text"
            fullWidth
            value={firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel shrink={false} sx={{ color: "var(--black)" }}>
            Last Name
          </InputLabel>
          <TextField
            size="small"
            name="lastName"
            type="text"
            fullWidth
            value={lastName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel shrink={false} sx={{ color: "var(--black)" }}>
            Email*
          </InputLabel>
          <TextField
            size="small"
            name="email"
            type="email"
            fullWidth
            value={email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel shrink={false} sx={{ color: "var(--black)" }} >
            Are You a Licensed Professional?*
          </InputLabel>
          <FormControl fullWidth>
            <Select
              size="small"
              id="Licensed-Professional"
              value={professional}
              onChange={handleProfessionalChange}
              displayEmpty
            >
              <MenuItem value="">Please Select</MenuItem>
              <MenuItem value="Yes">Professional</MenuItem>
              <MenuItem value="No">Student</MenuItem>
              <MenuItem value="Not sure...">Retail Consumer</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={
              !email || !validateEmail(email) || !professional
            }
            type="submit"
            fullWidth
            variant="btn-secondary"
            sx={{ height: "40px" }}
          >
            Subscribe
          </Button>
        </Grid>
        <Snackbar
          onClose={handleReset}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          autoHideDuration={6000}
          open={msg.length !== 0}
        >
          <Alert severity={severity}>{msg}</Alert>
        </Snackbar>
      </Grid>
    </Box>
  );
};

export default StayConnected;