import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { forwardRef, useEffect, useState } from "react";
import { normalizePhoneInput, validatePhoneInput } from "../utils";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useUpdatePhoneNumber } from "../hooks/useUpdatePhoneNumber";
import SMSIAgree from "./SMSIAgree";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditPhoneNumber = (props) => {
  const {
    header,
    phoneNumber,
    smsOptIn,
    handleSuccess,
    showCheckbox = false,
    hintText = "",
    IAgreeText = "",
    saveWidth = "0",
    validateOptIn = true,
  } = props;
  const [newPhone, setNewPhone] = useState();
  const [error, setError] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(smsOptIn);
  }, [smsOptIn]);

  const { mutate } = useUpdatePhoneNumber(handleSuccess, (error) => {
    setError(error);
  });

  useEffect(() => {
    setNewPhone(phoneNumber?.toString() || "");
  }, [phoneNumber]);

  const resetError = () => {
    setError("");
    setError(false);
  };

  const handlePhoneChange = (e) => {
    let phone = validatePhoneInput(e.target.value);
    setNewPhone(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newActualPhone = newPhone.replaceAll("-", "");
    if (newActualPhone === phoneNumber && smsOptIn === checked) {
      return;
    }
    mutate({ phone: newActualPhone, smsOptIn: checked });
  };

  const handleCheckChange = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <Box sx={{ padding: "1rem 1rem" }}>
      <Box
        onSubmit={handleSubmit}
        component="form"
        noValidate
        autoComplete="off"
      >
        <h2>{header}</h2>
        <TextField
          label="Phone Number"
          helperText={hintText}
          variant="outlined"
          fullWidth
          type="tel"
          placeholder="(123)-123-1234"
          sx={{ mt: 4, mb: 4, "& .MuiFormHelperText-root": { marginLeft: 0 } }}
          value={normalizePhoneInput(newPhone?.toString() || "")}
          onChange={handlePhoneChange}
          error={newPhone && newPhone.length !== 10}
        />
        <SMSIAgree
          checked={checked}
          text={IAgreeText}
          handleChange={handleCheckChange}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            disabled={
              validateOptIn &&
              (phoneNumber === newPhone ||
              newPhone?.length !== 10 ||
              error ||
              showCheckbox
                ? !checked
                : false)
            }
            variant="btn-secondary"
            type="submit"
            sx={{ width: saveWidth === "100" ?? "100%" }}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Snackbar
        onClose={resetError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        open={error}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </Box>
  );
};

export default EditPhoneNumber;
