export function getOutOfStockList(items) {
  if (items && items?.length > 0) {
    const result = items?.reduce((acc, item) => {
      if (item.inventory <= 0) {
        acc.push(item);
      }
      return acc;
    }, []);
    return result;
  }
  return [];
}
