import GyModal from "../GyModal";
import Box from "@mui/material/Box";
import VideoPlayer from "../VideoPlayer";

const ProductVideo = ({ src, poster, open, handleClose }) => {
  return (
    <GyModal open={open} handleClose={handleClose}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <VideoPlayer src={src} poster={poster} autoPlay={true} />
      </Box>
    </GyModal>
  );
};

export default ProductVideo;
