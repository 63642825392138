// import Box from "@mui/material/Box";
import CartThumbnail from "./CartThumbnail";

function CartList({ cartData }) {
  return (
    <>
      {cartData?.map((item, index) => {
        return <CartThumbnail data={item} key={index} />;
      })}
    </>
  );
}

export default CartList;
