import Image from "../components/shared/Image";
import PlayCircle from "../assets/img/PlayCircle.png";

const PlayButton = ({ isVideoView }) => {
  if (!isVideoView) {
    return null;
  }

  return (
    <Image
      src={PlayCircle}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      alt="icon"
    />
  );
};

export default PlayButton;
