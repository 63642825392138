export const hasValidEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const normalizedEmail = normalizeEmail(email);
  const hasInvalidDomain =
    /^(.*\.)?(coom|comm)$/.test(normalizedEmail.split("@")[1]) ||
    normalizedEmail.split("@")[1]?.includes("gnail");
  const domain = normalizedEmail.split(".").slice(-1)[0];
  const hasLessThanTwoCharsAfterDot = domain.length < 2;
  return (
    re.test(email) &&
    email === normalizedEmail &&
    !hasInvalidDomain &&
    !hasLessThanTwoCharsAfterDot
  );
};

export const normalizeEmail = (email) => {
  if (email) {
    return email.normalize("NFKD").replace(/[\u0300-\u036f]/g, "");
  }
  return "";
};

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const validatePhoneLength = (number, country) => {
  return country === "United States" && number?.length > 0
    ? number.length === 10
    : true;
};

export const validateZipLength = (zip, country) => {
  return country === "United States" && zip?.length > 0
    ? zip.length === 5
    : true;
};

export const sanitizeName = (name) => {
  if (name) {
    // Remove forbidden characters
    let sanitized = name.replace(/[<>:]/g, '');
    // Limit the name to 32 characters
    sanitized = sanitized.slice(0, 32);
    return sanitized;
  }
  return '';
}
