import { forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction={props.direction} ref={ref} {...props} />;
});

const GyModal = ({
  open,
  handleClose,
  children,
  maxWidth = "md",
  direction = "up",
  hasTransition = false,
  fullWidth = true,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <Dialog
        fullScreen={fullWidth ? fullScreen : false}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        {...(hasTransition
          ? {
              TransitionComponent: Transition,
              keepMounted: true,
              TransitionProps: { direction: direction },
            }
          : {})}
      >
        <DialogContent>
          <Box sx={btnBoxStyle}>
            <IconButton sx={iconStyle} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
};

const iconStyle = {
  color: "#c4bcb7",
  height: "auto",
  minWidth: "auto",
  "&:hover": { color: "#c4bcb7" },
};

const btnBoxStyle = {
  maxWidth: "100%",
  display: "flex",
  justifyContent: "flex-end",
};

GyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default GyModal;
