import { Fragment } from "react";
import { isProLoggedIn } from "../utils/jwt";
import RewardReferrals from "../components_b2b/RewardReferrals";
import useRewardReferrals from "../hooks/useRewardReferrals";
import ResponsiveBannerImage from "../components/ResponsiveBannerImage";
import SectionRegenRewardReferralBody from "../components_web/SectionRegenRewardReferralBody";

const RegenRewardReferralsPage = () => {
  const isPro = isProLoggedIn();
  const data = useRewardReferrals() || {};

  return isPro ? (
    <RegenRewardReferralsForPro data={data} />
  ) : (
    <RegenRewardReferralsForNonPro data={data} />
  );
};

const RegenRewardReferralsForNonPro = ({ data }) => {
  const { banner } = data || {};
  return (
    <Fragment>
      <ResponsiveBannerImage banner={banner} />
      <SectionRegenRewardReferralBody />
    </Fragment>
  );
};

const RegenRewardReferralsForPro = ({ data }) => {
  return <RewardReferrals data={data} />;
};

export default RegenRewardReferralsPage;
