import { ASSETS_BASE_URL } from "../constants";
export const media = {
  header: {
    eyeCreams: `${ASSETS_BASE_URL}/image/components-img/eyeCreams.png`,
    moisturizers: `${ASSETS_BASE_URL}/image/components-img/moisturizers.png`,
    cleanser: `${ASSETS_BASE_URL}/image/components-img/cleanser.png`,
    lipCare: `${ASSETS_BASE_URL}/image/components-img/lipCare.png`,
    masques: `${ASSETS_BASE_URL}/image/components-img/masques.png`,
    exfoliators: `${ASSETS_BASE_URL}/image/components-img/exfoliators.png`,
    dropdownImg: `${ASSETS_BASE_URL}/image/components-img/dropdownImg.png`,
    glymedLogo: `${ASSETS_BASE_URL}/image/website/logos/glymed_08-2022.png`,
  },
  landing: {
    stepProcess: `${ASSETS_BASE_URL}/image/landing-img/banner_img.png`,
    featured: `${ASSETS_BASE_URL}/image/landing-img/featuredImg.png`,
    homeImg1: `${ASSETS_BASE_URL}/image/landing-img/home_img1.jpeg`,
    homeImg3: `${ASSETS_BASE_URL}/image/landing-img/home_img3.png`,
    homeImg4: `${ASSETS_BASE_URL}/image/landing-img/home_img4.jpg`,
    sunset: `${ASSETS_BASE_URL}/image/landing-img/sunset-bck.png`,
    spray: `${ASSETS_BASE_URL}/image/landing-img/gy-spray.png`,
    newsBlog1: `${ASSETS_BASE_URL}/image/landing-img/newsBlog1.png`,
    rewardsImg: `${ASSETS_BASE_URL}/image/landing-img/rewardsImg.jpg`,
  },
  founder: {
    christineBanner: `${ASSETS_BASE_URL}/image/banner/Our_Founder_Website_Banner_2022.jpg`,
    christineImg1: `${ASSETS_BASE_URL}/image/founder-img/christine1.png`,
    sig: `${ASSETS_BASE_URL}/image/founder-img/christineSig.png`,
    ourStoryImg: `${ASSETS_BASE_URL}/image/founder-img/ourStory.png`,
    ourStory: `${ASSETS_BASE_URL}/image/founder-img/TheStoryofGlyMedPlus.mp4`,
    christineKutv: `${ASSETS_BASE_URL}/image/founder-img/christine_kutv.png`,
  },
  institute: {
    logo: `${ASSETS_BASE_URL}/image/Institute-img/institute-logo.png`,
    banner: `${ASSETS_BASE_URL}/image/Institute-img/institue-banner.jpeg`,
    instituteCalendar: `${ASSETS_BASE_URL}/image/Institute-img/calendar.jpeg`,
    instituteWebinar: `${ASSETS_BASE_URL}/image/Institute-img/institute_3.jpeg`,
    instituteBlog: `${ASSETS_BASE_URL}/image/Institute-img/resource.jpeg`,
    instituteResource: `${ASSETS_BASE_URL}/image/Institute-img/blog.jpeg`,
    instituteMoreInfo: `${ASSETS_BASE_URL}/image/Institute-img/institute_parallax3.jpeg`,
  },

  products: {
    header1: `${ASSETS_BASE_URL}/image/banner/all.png`,
  },
  uac: {
    athena: `${ASSETS_BASE_URL}/image/uac-img/athena.jpeg`,
    catherine: `${ASSETS_BASE_URL}/image/uac-img/catherine.jpeg`,
    dana: `${ASSETS_BASE_URL}/image/uac-img/dana.jpeg`,
    iconA: `${ASSETS_BASE_URL}/image/uac-img/iconA.svg`,
    iconB: `${ASSETS_BASE_URL}/image/uac-img/iconB.svg`,
    iconC: `${ASSETS_BASE_URL}/image/uac-img/iconC.svg`,
    iconD: `${ASSETS_BASE_URL}/image/uac-img/iconD.svg`,
    iconE: `${ASSETS_BASE_URL}/image/uac-img/iconE.svg`,
    iconF: `${ASSETS_BASE_URL}/image/uac-img/iconF.svg`,
    iconG: `${ASSETS_BASE_URL}/image/uac-img/iconG.svg`,
    iconH: `${ASSETS_BASE_URL}/image/uac-img/iconH.svg`,
    iconI: `${ASSETS_BASE_URL}/image/uac-img/iconI.svg`,
    iconJ: `${ASSETS_BASE_URL}/image/uac-img/iconJ.svg`,
    iconK: `${ASSETS_BASE_URL}/image/uac-img/iconK.svg`,
    jimmy: `${ASSETS_BASE_URL}/image/uac-img/jimmy.jpeg`,
    kaylee: `${ASSETS_BASE_URL}/image/uac-img/kaylee.jpeg`,
    kelley: `${ASSETS_BASE_URL}/image/uac-img/kelley.jpeg`,
    mackenzie: `${ASSETS_BASE_URL}/image/uac-img/mackenzie.jpeg`,
    patricia: `${ASSETS_BASE_URL}/image/uac-img/patricia.jpeg`,
    shipping: `${ASSETS_BASE_URL}/image/uac-img/patricia.jpeg`,
    uacBanner: `${ASSETS_BASE_URL}/image/uac-img/uacBanner.png`,
    uacAbout: `${ASSETS_BASE_URL}/image/uac-img/uacabout.png`,
    uacLogo: `${ASSETS_BASE_URL}/image/uac-img/uaclogo.png`,
  },
  blog: {
    blogHeader: `${ASSETS_BASE_URL}/image/blog-img/gyblogHeader.png`,
    blogHeader2: `${ASSETS_BASE_URL}/image/blog-img/gyBlogHeader2.png`,
    blogHeaderImg: `${ASSETS_BASE_URL}/image/blog-img/gyBlogHeaderImg.png`,
    psuedoImg: `${ASSETS_BASE_URL}/image/blog-img/psuedoImg.png`,
  },
  calendar: {
    calendarHeader: `${ASSETS_BASE_URL}/image/calendar-img/calendar.png`,
    calendarAnim: `${ASSETS_BASE_URL}/image/calendar-img/calendarAnim.svg`,
    calendarSignUp: `${ASSETS_BASE_URL}/image/calendar-img/calendarImg0.png`,
  },
  protocol: {
    protocolHeader: `${ASSETS_BASE_URL}/image/calendar-img/calendar.png`,
  },
  education: {
    educationSubHeaderImg: `${ASSETS_BASE_URL}/image/education-img/education.jpg`,
  },
  resources: {
    banner: `${ASSETS_BASE_URL}/image/banner/resource_library_banner.png`,
  },
  BecomePro: {
    banner1: `${ASSETS_BASE_URL}/image/website/content/become-pro/banner_become_a_pro@2x.png`,
    banner2: `${ASSETS_BASE_URL}/image/website/content/become-pro/becomepro_facial.jpg`,
    banner3: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_click.png`,
    banner4: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_products.png`,
    banner5: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_regen_logo.png`,
    banner6: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_online_retail.jpg`,
    banner7: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_award.png`,
    banner8: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_greade.png`,
    banner9: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_natural.png`,
    banner10: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_cruelty.png`,
    banner11: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_made_usa.png`,
    banner12: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_skin_science.png`,
    banner13: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_education.png`,
    banner14: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_progress.png`,
    banner15: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_location.png`,
    banner16: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_world.png`,
    banner17: ``,
    benefitsImg: `${ASSETS_BASE_URL}/image/becomepro-img/becomeProImg.png`,
    registerImg: `${ASSETS_BASE_URL}/image/website/content/become-pro/become_a_pro_click.png`,
    regenRetailHtmlForm: `${ASSETS_BASE_URL}/html/gmp_professional_form.html`,
  },
  checkout: {
    silverTierImg: `${ASSETS_BASE_URL}/image/website/content/checkout/silver.svg`,
    goldTierImg: `${ASSETS_BASE_URL}/image/website/content/checkout/gold.svg`,
    bronzeTierImg: `${ASSETS_BASE_URL}/image/website/content/checkout/bronze.svg`,
    diamondTierImg: `${ASSETS_BASE_URL}/image/website/content/checkout/diamond.svg`,
    platinumTierImg: `${ASSETS_BASE_URL}/image/website/content/checkout/platinum.svg`,
  },
  rewards: {
    star: `${ASSETS_BASE_URL}/image/website/content/checkout/stars.svg`,
    mysteryProductPlaceholder: `${ASSETS_BASE_URL}/image/website/content/rewards/regen_rewards_monthly_mystery_discount_product.jpg`,
  },
  myStore: {
    banner: `${ASSETS_BASE_URL}/image/banner/My_Store_Website_Banner_2022.jpg`,
  },
  school: {
    myAccountBanner: `${ASSETS_BASE_URL}/image/banner/school_home_banner.png`,
  },
  regenRewardReferralHtml: {
    beginYourReferral: `${ASSETS_BASE_URL}/html/gmp_referral_form.html`,
  },
  regenRewardReferralImg: {
    oldBanner3360: `${ASSETS_BASE_URL}/image/banner/gmp_referral_banner@2x.png`,
    oldBanner1536: `${ASSETS_BASE_URL}/image/banner/gmp_referral_banner_portrait.png `,
    oldBanner393: `${ASSETS_BASE_URL}/image/banner/gmp_referral_banner.png`,
  },
  gaain: {
    whatIsGaain: `${ASSETS_BASE_URL}/image/util/what-is-gaain.jpg`,
  },
  charity: {
    desktop: `${ASSETS_BASE_URL}/image/banner/Charity_desktopbanner_1101.png`,
    portrait: `${ASSETS_BASE_URL}/image/banner/Charity_mobilebanner_1101.png`,
  },
};
