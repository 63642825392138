import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";

export const RewardImageIcon = styled("img")((props) => ({
  display: "flex",
  width: "3rem",
  minHeight: "51.69px",
  ...props,
}));

export const ListBullet = styled(Box)(({ color, theme, display }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: "50%",
  display: display || "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: `var(${color})`,
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(0.5),
}));

export const StyledSpend = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    maxWidth: theme.spacing(18),
  },
}));

export const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, progresscolor }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[300],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: `var(${progresscolor})`,
    },
  })
);

export const Points = styled(Typography)(() => ({
  fontSize: "2rem",
  fontFamily: "Nimbus-Sans-Light",
  paddingBottom: 0,
}));

export const ListItem = styled(Typography)(({ theme }) => ({
  fontSize: "0.8rem",
  paddingBottom: 0,
  fontFamily: "Nimbus-Sans-Light",
  fontWeight: 300,
  color: "#231F20",
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
  },
}));

export const BenefitTitle = styled(Typography)(() => ({
  fontSize: "1rem",
  paddingBottom: 0,
  textTransform: "uppercase",
}));

export const StarImage = styled("img")(({ invert }) => ({
  width: "1.5rem",
  height: "1.5rem",
  transform: invert === "true" ? "rotate(90deg)" : "",
}));

export const ExpiringPointIconBox = styled(Box)(() => ({
  height: "30px",
  width: "30px",
  borderRadius: "50%",
  backgroundColor: "#4c5748",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ExpiringPointIcon = styled(HourglassFullIcon)(() => ({
  color: "#fff",
}));

export const ExpiringPointText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  paddingBottom: 0,
  display: "flex",
  justifyContent: "center",
}));
