import { regenBasic } from "../utils/axios";
import { getUserId } from "../utils/jwt";

export const getRetailOrders = async (year) => {
  const userId = getUserId();
  const { data } = await regenBasic.get(
    `/users/${userId}/retailClients/orders?year=${year}`
  );
  return data;
};


export const getRetailOrder = async (orderId) => {
  const userId = getUserId();
  const { data } = await regenBasic.get(
    `/users/${userId}/retailClients/orders/${orderId}`
  );
  return data;
};
