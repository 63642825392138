import css from "../styles_web/SectionRegenRewardReferralBody.module.css";
import Button from "@mui/material/Button";

const SectionRegenRewardReferralBody = () => {
  const handleOpen = () => {
    window.open(
      "https://forms.monday.com/forms/9164134ff592cb5b7edd000bf5366b17?r=use1"
    );
  };

  return (
    <section>
      <div className={css["gy-regen-reward-referral-body"]}>
        <div className={css["gy-regen-reward-referral-body-heading"]}>
          <h2>EARN 20,000 POINTS WITH REGEN® REWARDS REFERRALS</h2>
        </div>
        <div className={css["gy-regen-reward-referral-body-content"]}>
          <p className={css["gy-regen-reward-referral-body-content-text"]}>
            What better way to help a colleague than by sharing the incredible
            benefits you experience as a GlyMed Pro? Refer a skincare
            professional and earn 20,000 REGEN® Rewards points* when they spend
            $350 (excluding discounts and taxes) in the first 30 days of opening
            their account. Your colleague will also receive 20,000 points, which
            they can redeem to purchase our unmatched skincare products or
            enroll in advanced aesthetic courses.
          </p>
          <p className={css["gy-regen-reward-referral-body-content-text"]}>
            GlyMed Plus is the only skincare company actively striving to grow
            our partner’s businesses and help them generate more income. With
            our industry-leading skincare and innovative business solutions, our
            partners can significantly boost their revenue. In fact, hundreds of
            our partners receive substantial payouts directly from us every
            week.
          </p>
          <p className={css["gy-regen-reward-referral-body-content-text"]}>
            Don’t miss out on this opportunity to support your colleague in
            starting their own GlyMed Plus partnership. Click below to get them
            started on their journey to success!
          </p>
          <p className={css["gy-regen-reward-referral-body-content-text"]}>
            *For U.S. Pro Accounts only.
          </p>
        </div>
        <br />
        <br />
        <div className={css["gy-regen-reward-referral-body-button"]}>
          <Button onClick={handleOpen} variant="btn-secondary">
            Refer a Pro
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SectionRegenRewardReferralBody;
