import { atom, selectorFamily } from "recoil";

export const OpenFilterOverlayState = atom({
  key: "OpenFilterOverlayState",
  default: false,
});

export const ResetFilterState = atom({
  key: "ResetFilter",
  default: false,
});

export const IsProductFilterAppliedState = atom({
  key: "IsProductFilterApplied",
  default: false,
});

export const SearchInputState = atom({
  key: "SearchInput",
  default: "",
});

export const OpenNotifyMeState = atom({
  key: "NotifyMe",
  default: false,
});

export const SubscribeSuccessState = atom({
  key: "ShowNotifyMeStatus",
  default: false,
});

export const NotifyMeSKUState = atom({
  key: "NotifyMeSKU",
  default: "",
});

export const NotifyMeMessageState = atom({
  key: "NotifyMeMessage",
  default: "",
});

export const ResubscribeSuccessState = atom({
  key: "UnsubscribeSuccess",
  default: false,
});

export const productCategories = atom({
  key: "productCategories",
  default: [],
});

export const ScrollToSkuState = atom({
  key: "ScrollToSku",
  default: "",
});

export const productCategoryBanner = selectorFamily({
  key: "productCategoryBanner",
  get:
    (type) =>
    ({ get }) => {
      const categories = get(productCategories);
      const category = categories?.find((item) => item.id === type) || [];
      return category?.banner ? [category.banner] : [];
    },
});

export const productCategoryVericalBanner = selectorFamily({
  key: "productCategoryVericalBanner",
  get:
    (type) =>
    ({ get }) => {
      const categories = get(productCategories);
      const category = categories?.find((item) => item.id === type) || [];
      return category?.verticalBanner ? category?.verticalBanner : [];
    },
});
