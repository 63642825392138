import { numberWithCommas } from "../../utils";
import { checkRewardEligibility, getUser } from "../../utils/jwt";
import { StyledBodyLight } from "../../styled";
import Box from "@mui/material/Box";
import Image from "../shared/Image";
import { ASSETS_BASE_URL } from "../../constants";
import css from "../../styles_web/RewardsOnOrder.module.css";

const RewardsOnOrder = ({ rewardsData }) => {

  const { rewardsTier } = getUser() || {}
  const rewardPoints = rewardsData?.pointsReceive
  const currentPoints = rewardPoints ? rewardPoints : 0;
  const eligibleForRewards = checkRewardEligibility();

  if (!eligibleForRewards) {
    return null;
  }

  if (currentPoints > 0) {
    return (
      <Box className={css.container}>
        <Box className={css.rewardBox}>
          {getIcon(rewardsTier)}
          <StyledBodyLight>REGEN® Rewards</StyledBodyLight>
        </Box>
        <StyledBodyLight>
          Earn <b>{numberWithCommas(currentPoints)}</b> points at checkout
        </StyledBodyLight>
      </Box>
    );
  }

  return null;
};

const getIcon = (tier) => {
  if (tier) {
    return (
      <Image
        className={css.rewardIcon}
        src={`${ASSETS_BASE_URL}/image/website/content/checkout/${tier.toLowerCase()}.svg`}
        alt={`tier-${tier}`}
      />
    );
  }
  return (
    <Image
      className={css.rewardIcon}
      src={`${ASSETS_BASE_URL}/image/website/content/checkout/pro.svg`}
      alt="tier-pro"
    />
  );
};

export default RewardsOnOrder;
