import { useMutation } from "react-query";
import { stayConnected } from "../services/stayConnected";

export const useStayConnected = (handleSuccess, handleError) => {
  return useMutation(stayConnected, {
    onSuccess: () => {
      handleSuccess();
    },
    onError: (err) => {
      handleError(err?.message);
    },
  });
};
