export const PAYMENT = "Payment";
export const PAYMENT_METHOD = "Payment Method";
export const PO_NUMBER = "PO Number";
export const PROMO_CODE = "Promo Code";
export const EDIT = "Edit";
export const CHANGE = "Change";
export const APPLY = "Apply";
export const REVIEW_YOUR_ORDER = "Review your order";
export const SELECT_PAYMENT = "Select Payment";
export const ADD_NEW_PAYMENT_METHOD = "Add new payment method";
export const ADD_NEW_CARD = "Add new card";
export const REMOVE_SELECTED_CARD = "Remove Selected Card";
export const SELECT_PAYMENT_METHOD = "Select payment method";
export const NAME_ON_CARD = "Name on card";
export const CREDIT_CARD_NUMBER = "Credit Card Number";
export const EXPIRY_DATE = "EXP MM/YY";
export const ZIP_POSTAL = "Zip/Postal";
export const CVV = "CVV";
export const SAVE_PAYMENT_METHOD = "Save payment method";
export const REVIEW = "Review";
export const QUICK_VIEW = "Quick View";
export const REMOVE = "Remove";
export const QTY = "Qty";
export const PRICE = "Price";
export const COUPON = "Promo Code";
export const NET_PRICE = "Net Price";
export const TOTAL = "Total";
export const PLACE_YOUR_ORDER = "Place your order";
export const REGEN_REWARDS = "REGEN Rewards";
export const REWARDS_POINTS = "Reward Points";
export const USE_MY_POINTS = "Use My Points";
export const USE_REGEN_REWARDS = "Use REGEN Rewards Points";
export const ITEMS = "Items";
export const SUBTOTAL = "Subtotal";
export const ESTIMATED_SHIPPING = "Shipping & Handling";
export const DISCOUNTS = "Discounts";
export const ESTIMATED_TAX = "Tax";
export const RETAIL_DELIVERY_FEES = "Retail Delivery Fees";
export const THANK_YOU_FOR_YOUR_ORDER = "Thank you for your order!";
export const ORDER_DETAILS = "Order Details";
export const SILVER = "Silver";
export const GOLD = "Gold";
export const CURRENT_LEVEL = "Current Level";
export const NEXT_LEVEL = "Next Level";
export const SPENT_PER_QUARTER = "spent per quarter";
export const SPEND = "Spend";
export const ORDER_AGAIN = "Order Again";
export const ORDER_PROCESS_MESSAGE =
  "Please do not refresh or navigate between pages while your order is being placed...";
export const CARD_DETAILS = "Card details";

export const MEMBERSHIP_NEW_TIER_MESSAGE = [
  {
    name: "Bronze",
    image: "bronzeTierImg",
    message: "Hooray!",
    color: "#C67D3B",
    rank: 0,
    bonusPoints: 4000,
  },
  {
    name: "Silver",
    image: "silverTierImg",
    message: "I’m stoked!",
    color: "#B9B4A9",
    rank: 1,
    bonusPoints: 6500,
  },
  {
    name: "Gold",
    image: "goldTierImg",
    message: "Yay!",
    color: "#E1B741",
    rank: 2,
    bonusPoints: 9000,
  },
  {
    name: "Platinum",
    image: "platinumTierImg",
    message: "Woohoo!",
    color: "gray",
    rank: 3,
    bonusPoints: 12500,
  },
  {
    name: "Diamond",
    image: "diamondTierImg",
    message: "That’s Dope!",
    color: "#8ECFE5",
    rank: 4,
    bonusPoints: 20000,
  },
];


export const PERMISSSIONS = {
  APPLY_OVERALL_DISCOUNT: "applyOverallDiscount",
  CHECKOUT: "checkout",
  EDIT_ORDER_ITEM: "editOrderItem",
}
