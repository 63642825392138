import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    if (
      !location?.pathname?.includes("/products") &&
      location?.pathname !== "/cart" &&
      !location?.pathname?.includes("/tradeshow/") &&
      location?.pathname !== "/"
    ) {
      window?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [location]);
};

export default ScrollToTop;
