import { useMutation } from "react-query";
import { useSetRecoilState } from "recoil";
import {
  ResubscribeSuccessState,
  SubscribeSuccessState,
} from "../atoms/productAtoms";
import { updateNotify, updateUnsubscribe } from "../services/products";

export const useUpdateNotify = () => {
  const setShowNotifyMeSuccess = useSetRecoilState(SubscribeSuccessState);
  const setUnsubscribeSuccess = useSetRecoilState(ResubscribeSuccessState);
  const mutation = useMutation(updateNotify, {
    onSuccess: () => {
      setShowNotifyMeSuccess(true);
      setUnsubscribeSuccess(true);
    },
  });
  return mutation;
};

export const useUpdateUnSubscribe = () => {
  const mutation = useMutation(updateUnsubscribe);
  return mutation;
};
