import { Fragment, useEffect } from "react";

const DisableRightClick = ({ children }) => {
  const disableContextMenu = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (process.env.REACT_APP_APP_MODE !== "dev") {
      document.addEventListener("contextmenu", disableContextMenu);
    }

    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default DisableRightClick;
