import ProductSlide from "./ProductSlide";
import ProductVideo from "./ProductVideo";
import { Navigation, Pagination } from "swiper";
import { memo, useEffect, useState } from "react";
import { ASSETS_BASE_URL } from "../../constants";
import { Swiper, SwiperSlide } from "swiper/react";

const ProductSwiper = memo(({ skuSet, skuSetWithName, rootSku, hideVideo }) => {
  const [swiper, setSwiper] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    if (skuSet) {
      swiper?.slideTo(0);
    }
  }, [skuSet, swiper]);

  const getAltTag = (sku) => {
    const item = skuSetWithName?.find((item) => item.sku === sku)
    return item?.shortName || sku
  }

  return (
    <>
      <Swiper
        onSwiper={setSwiper}
        spaceBetween={10}
        navigation={skuSet?.length > 1 || !hideVideo}
        pagination={{
          clickable: true,
        }}
        modules={
          skuSet?.length > 0 && skuSet[0] !== ""
            ? skuSet?.length < 2 && hideVideo
              ? []
              : [Pagination, Navigation]
            : [Pagination, Navigation]
        }
        style={{ maxWidth: "100%", paddingRight: "2px", paddingBottom: 40 }}
      >
        {skuSet.map((sku) => {
          return (
            <SwiperSlide key={sku}>
              <ProductSlide sku={sku} alt={getAltTag(sku)} />
            </SwiperSlide>
          );
        })}

        {!hideVideo && (
          <SwiperSlide>
            <ProductSlide
              sku={rootSku}
              isVideoSlide={true}
              handleOpen={handleOpen}
              alt={getAltTag(rootSku)}
            />
          </SwiperSlide>
        )}
      </Swiper>
      <ProductVideo
        poster={`${ASSETS_BASE_URL}/image/product/video-thumbnail/${rootSku}-P.png`}
        src={`${ASSETS_BASE_URL}/image/product/retail/${rootSku}-V.mp4`}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
});
export default ProductSwiper;
