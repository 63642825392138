import { useMutation, useQueryClient } from "react-query";
import { updateUserPhoneNumber } from "../services/user";

export const useUpdatePhoneNumber = (handleSuccess, handleError) => {
  const queryClient = useQueryClient();
  return useMutation("updatePhoneNumber", updateUserPhoneNumber, {
    onSuccess: () => {
      queryClient.invalidateQueries("userById");
      handleSuccess();
    },
    onError: (error) => handleError(error?.message),
  });
};
