import { Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import css from "../../styles/SMSOptIn.module.css";
import PhoneDialog from "./PhoneDialog";
import { useEffect, useState } from "react";
import { getHasImpersonate, getUser } from "../../utils/jwt";
import { useSetRecoilState } from "recoil";
import { smsOptInShowingState } from "../../atoms/userAtoms";

const SMSOptIn = (props) => {
  const { showJustDialog = false } = props;
  const [editPhone, setEditPhone] = useState(false);
  const [showOptIn, setShowOptIn] = useState(true);
  const setSmsOptInShowing = useSetRecoilState(smsOptInShowingState);
  const user = getUser();

  const hasImpersonate = getHasImpersonate();

  useEffect(() => {
    if (!user) {
      setShowOptIn(false);
    }
    if (localStorage.getItem("smsNotNow") === "true") {
      setShowOptIn(false);
    } else if (user?.smsOptIn) {
      setShowOptIn(!user.smsOptIn);
    }
  }, [user]);

  useEffect(() => {
    setSmsOptInShowing(showOptIn);
  }, [showOptIn, setSmsOptInShowing]);

  useEffect(() => {
    if (showJustDialog) {
      setEditPhone(true);
    }
  }, [showJustDialog]);

  const handleSMSNotNow = () => {
    setShowOptIn(false);
    localStorage.setItem("smsNotNow", true);
  };

  if (!user || localStorage.getItem("smsNotNow") === "true" || !showOptIn) {
    setSmsOptInShowing(false);
    return null;
  }

  return (
    <>
      {!showJustDialog && (
        <div className={css["opt-in"]} style={{marginTop: hasImpersonate ? "39px" : "0"}}>
          <Grid
            container
            xs={12}
            sm={11}
            md={9}
            xl={7}
            className={css["opt-in-container"]}
          >
            <Grid container className={css["opt-in-main"]}>
              <h4
                className={css["opt-in-text"]}
                style={{ fontFamily: "Nimbus-sans-regular", fontSize: "20px" }}
              >
                Get Exclusive Offers on your phone
                <u
                  className={css["sms-sign-up"]}
                  onClick={() => setEditPhone(true)}
                >
                  Sign up
                </u>
              </h4>
            </Grid>
            <IconButton
              className={css["opt-in-close"]}
              onClick={handleSMSNotNow}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </div>
      )}
      <PhoneDialog
        editPhone={editPhone}
        handleClose={() => setEditPhone(false)}
        handleSuccess={() => setShowOptIn(false)}
      />
    </>
  );
};

export default SMSOptIn;
