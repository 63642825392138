// import { regenAdmin } from "../utils/axios";
import { regenBasic } from "../utils/axios";
import { getUserId } from "../utils/jwt";
import { getDeviceId } from "../utils/jwt";

export const searchProducts = async (query) => {
  const url = `/products/?search=${query}`;
  const { data } = await regenBasic.get(url);
  return data;
};

export const getBundles = async ({ pageParam = 1 }) => {
  const userId = getUserId();
  const url = `/recommendations/${userId}?page=${pageParam}&perPage=10`;
  const { data } = await regenBasic.get(url);
  return data;
};

export const getBundleById = async (bundleId) => {
  const userId = getUserId();
  const url = `/recommendations/${userId}/bundle/${bundleId}`;
  const { data } = await regenBasic.get(url);
  return data;
};

export const getBundleByToken = async (token) => {
  const userId = getUserId();
  const url = `/recommendations/${token}/userId/${userId}`;
  const { data } = await regenBasic.get(url);
  return data;
};

export const createBundle = async (bundle) => {
  const userId = getUserId();
  const url = `/recommendations/${userId}`;
  const { data } = await regenBasic.post(url, bundle);
  return data;
};

export const updateBundle = async (bundle) => {
  const { bundleId, ...bundleData } = bundle;
  const userId = getUserId();
  const url = `/recommendations/${userId}/bundle/${bundleId}`;
  const { data } = await regenBasic.put(url, bundleData);
  return data;
};

export const deleteBundle = async (bundleId) => {
  const userId = getUserId();
  const url = `/recommendations/${userId}/bundle/${bundleId}`;
  const { data } = await regenBasic.delete(url);
  return data;
};

export const searchBundle = async (bundleName) => {
  const userId = getUserId();
  const url = `/recommendations/${userId}/search/${bundleName}`;
  const { data } = await regenBasic.get(url);
  return data;
};

export const downloadBundleReport = async (bundleId) => {
  const userId = getUserId();
  const url = `/recommendations/user/${userId}/report/${bundleId}`;
  const { data } = await regenBasic.get(url);
  return data;
};


export const getAdminBundleByToken = async (token) => {
  const userId = getUserId();
  const deviceId = getDeviceId();
  const url = `/admin/bundle/${token}/user/${userId ? userId : deviceId}`;
  const { data } = await regenBasic.get(url);
  return data;
};