import { atom } from "recoil";

export const GlobalLoaderMessageState = atom({
  key: "GlobalLoaderMessage",
  default: "",
});

export const GlyMedSnackbarState = atom({
  key: "GlyMedSnackbarState",
  default: { message: "", severity: "success", open: false },
});
