import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductQuickLook from "../components/ProductDetails/ProductQuickLook";
import Loader from "../components/Loader";
import ErrorMessage from "../components/ErrorMessage";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useProduct } from "../hooks";
import { isEmpty } from "../utils";
import GlyMedSnackbar from "../components/GlyMedSnackbar";

const ProductQuickLookPage = ({ open = true, onClose, skuParam }) => {
  const params = useParams();
  const sku = params.sku || skuParam;
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleSuccess = (data) => {
    if (isEmpty(data) || data === null) {
      navigate("/products");
    }
  };

  const {
    data: product = {},
    isLoading,
    isError,
    error,
  } = useProduct(sku, handleSuccess);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else if (location?.state?.backgroundLocation?.pathname) {
      const searchPath = location.state.backgroundLocation.search
        ? location.state.backgroundLocation.search
        : "";
      navigate(location.state.backgroundLocation.pathname + searchPath);
    } else {
      navigate(-1);
    }
  };

  const renderContent = (isLoading, isError, error, product) => {
    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return (
        <ErrorMessage
          errorMessage={error.message}
          style={{ minHeight: "53vh" }}
        />
      );
    }

    if (product && product.sku) {
      return <ProductQuickLook {...product} handleClose={handleClose} />;
    }

    return null;
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="product-dialog"
      >
        {renderContent(isLoading, isError, error, product)}
      </Dialog>
      <GlyMedSnackbar />
    </div>
  );
};

export default ProductQuickLookPage;
