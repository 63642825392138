import { useQuery, useMutation, useQueryClient } from "react-query";
import { getUserId } from "../utils/jwt";
import {
  getShippingAddress,
  editAddress,
  addNewAddress,
  makeAddressDefault,
  deleteAddress,
  getUserById,
  acceptTermsAndCondition,
  makeAddressMain,
} from "../services";
import JSONData from "../assets/JSON/my_account.json";

export const useGetAddress = () => {
  const userId = getUserId();
  return useQuery(["address", userId], () => getShippingAddress(userId), {
    retry: 0,
    enabled: !!userId,
  });
};

export const useUpdateAddress = () => {
  const queryClient = useQueryClient();

  return useMutation(editAddress, {
    onSuccess: () => {
      queryClient.invalidateQueries(["address"]);
    },
    onError: () => {
      queryClient.invalidateQueries(["address"]);
    },
  });
};

export const useAddNewAddress = () => {
  const queryClient = useQueryClient();

  return useMutation(addNewAddress, {
    onSuccess: () => {
      queryClient.invalidateQueries(["address"]);
    },
    onError: () => {
      queryClient.invalidateQueries(["address"]);
    },
  });
};

export const useMakeAddressDefault = () => {
  const queryClient = useQueryClient();

  return useMutation(makeAddressDefault, {
    onSuccess: () => {
      queryClient.invalidateQueries(["address"]);
    },
    onError: () => {
      queryClient.invalidateQueries(["address"]);
    },
  });
};

export const useDeleteAddress = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteAddress, {
    onSuccess: () => {
      queryClient.invalidateQueries(["address"]);
    },
    onError: () => {
      queryClient.invalidateQueries(["address"]);
    },
  });
};

export const useGetUserById = () => {
  const userId = getUserId();
  return useQuery(["userById", userId], () => getUserById(userId), {
    retry: 0,
    enabled: !!userId,
  });
};

export const useAcceptTerms = () => {
  const queryClient = useQueryClient();

  return useMutation(acceptTermsAndCondition, {
    onSuccess: () => {
      queryClient.invalidateQueries("userById");
    },
    onError: () => {
      queryClient.invalidateQueries("userById");
    },
  });
};

export const useGetMyAccountContent = () => {
  const data = JSON.parse(JSON.stringify(JSONData));

  return {
    ...data,
  };
};

// Make Address MAIN
export const useMakeAddressMain = (onSuccess, onError) => {
  return useMutation(makeAddressMain, {
    onSuccess: () => {
      onSuccess && onSuccess();
    },
    onError: () => {
      onError && onError();
    },
  });
};
