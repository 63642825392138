import JSONData from "../assets/JSON/reward-referrals.json";

const useRewardReferrals = () => {
  const data = JSON.parse(JSON.stringify(JSONData));

  return {
    ...data,
  };
};

export default useRewardReferrals;
