import { useIntercom } from "react-use-intercom";
import { useEffect } from "react";
import { getIntercomUser, getRegenToken } from "../../utils/jwt";

function Chatbot() {
  const { boot, shutdown, isOpen, update } = useIntercom();
  const jwtToken = getRegenToken();

  useEffect(() => {
    if(!jwtToken) {
      shutdown();
      boot();
      return;
    }
    const userDetails = getIntercomUser();
    boot(userDetails);
  }, [boot, jwtToken, shutdown]);

  useEffect(() => {
    const userDetails = getIntercomUser();
    if(isOpen && !userDetails) {
      update({
        name: ``,
      });
    }
  },[isOpen, update])

  return <></>;
}

export default Chatbot;