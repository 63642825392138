import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useUpdateCart } from "../../hooks/useManageCart";
import GyModal from "../GyModal";

function OutOfStockDialog(props) {
  const { handleClose, items, handleOutOfStockSuccess } = props;
  const mutation = useUpdateCart();

  const handleProceed = () => {
    mutation.mutate({
      itemsRemoved: items?.map((item) => item.sku),
      rewardPoints: 0,
      couponCode: "",
      shipVia: "",
      shippingRate: "",
      shipName: "",
      discount: 0,
      isPercent: false,
    });
    handleOutOfStockSuccess();
  };

  return (
    <GyModal open={true} handleClose={handleClose} maxWidth="sm">
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={10} mb={4}>
          <Typography variant="subtitle2">
            Proceeding to Check Out will remove the out of stock items from your
            order
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end" mb={2}>
          <Button
            variant="btn-secondary"
            sx={{ marginRight: 3, width: "8rem" }}
            onClick={handleProceed}
          >
            Proceed
          </Button>
          <Button
            variant="btn-gray"
            sx={{ marginRight: 3, width: "8rem" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </GyModal>
  );
}

export default OutOfStockDialog;
