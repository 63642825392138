import { regenBasic } from "../utils/axios";
import { getUserId } from "../utils/jwt";

export const getOrders = async (page, perPage, order, selectedYear) => {
  const userId = getUserId();
  let url = `/user/${userId}/order?page=${page}&perPage=${perPage}&order=${order}`;
  const newUrl = selectedYear ? url + `&year=${selectedYear}` : url;
  const { data } = await regenBasic.get(newUrl);
  return data;
};

export const getOrderById = async (orderId) => {
  const userId = getUserId();
  const { data } = await regenBasic.get(`/user/${userId}/order/${orderId}`);
  return data;
};

export const updateOrder = async (payload) => {
  const userId = getUserId();
  const { data } = await regenBasic.post(`/order/${userId}`, payload);
  return data;
};

export const courseRegistration = async (postData) => {
  const userId = getUserId();
  const { data } = await regenBasic.post(
    `/course/register/${userId}`,
    postData
  );
  return data;
};

export const guestCourseRegistration = async (postData) => {
  const { data } = await regenBasic.post(`/course/register/guest`, postData);
  return data;
};

export const getCourseRegistrationStatus = async (courseId) => {
  const userId = getUserId();
  const { data } = await regenBasic.get(`/user/${userId}/courseId/${courseId}`);
  return data;
};
