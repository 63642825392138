import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CouponHintFourSteps = ({ meta }) => {
const navigate = useNavigate();

  const handleNavigation = () => {
    navigate({ pathname: `/products`, search: `?fourstep=${meta.missingStep}`});
  };

  return (
    <Box>
      Add one more {meta.requiredProductSize ? meta.requiredProductSize + " size" : ""} item
      from the <b style={{ cursor: 'pointer'}} onClick={handleNavigation}>{meta.missingStep}</b> category to qualify for the 4-step treatment discount.
    </Box>
  );
};

export default CouponHintFourSteps;
