import { useQuery } from "react-query";
import { getProductBySku } from "../services/products";

function useProduct(productId, handleSuccess) {
  return useQuery(["product", productId], () => getProductBySku(productId), {
    onSuccess: (data) => {
        handleSuccess?.(data);
    }
  });
}

export default useProduct;
