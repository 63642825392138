import { useQuery } from "react-query";
import { getShippingAddress, getShippingOption } from "../services";
import { getUserId } from "../utils/jwt";

export const useGetAddress = () => {
  const userId = getUserId();
  return useQuery(["address", userId], () => getShippingAddress(userId), {
    enabled: userId ? true : false,
  });
};


export const useGetShippingOptions = () => {
  const userId = getUserId();
  return useQuery(["shippingOption", userId], () => getShippingOption(userId), {
    retry: false,
    enabled: !!userId,
  });
}