import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {getUser} from "./jwt"

if (process.env.REACT_APP_APP_MODE === "production") {
  Sentry.init({
    dsn: "https://e5a8f41236c14ecd8b1f694012d8d78d@o526249.ingest.sentry.io/5867669",
    environment: "production",
    integrations: [new BrowserTracing()],
    beforeSend: (event) => {
      const {accountId, accountName} = getUser() || {};
      if (accountId) {
        event.user = {accountId, accountName}
      }
      return event;
    },
    tracesSampleRate: 1.0,
  });
}

//Uncomment if you want debug something for dev env.
// if (process.env.REACT_APP_APP_MODE === "dev") {
//   Sentry.init({
//     dsn: "https://e5a8f41236c14ecd8b1f694012d8d78d@o526249.ingest.sentry.io/5867669",
//     environment: "dev",
//     integrations: [new BrowserTracing()],
//   });
// }

// =====================================================================================

//Sentry has only one stage environment but we have 3
//qa, uat and staging4, all are having REACT_APP_APP_MODE = stage
//If you want to debug somehing on these environments and see the log in sentry
//then deploy it on qa or change the defaultEnv variable to
//the name of environment where your code has been deployed.

//It can be qa, uat or staging4
const defaultEnv = "qa";

if (
  process.env.REACT_APP_APP_MODE === "stage" &&
  process.env.REACT_APP_API_URL?.includes(defaultEnv)
) {
  Sentry.init({
    dsn: "https://e5a8f41236c14ecd8b1f694012d8d78d@o526249.ingest.sentry.io/5867669",
    environment: "stage",
    integrations: [new BrowserTracing()],
    beforeSend: (event) => {
      const {accountId, accountName} = getUser() || {};
      if (accountId) {
        event.user = {accountId, accountName}
      }
      return event;
    },
  });
}
