import { useEffect, useState } from "react";
import { getUser } from "../../utils/jwt";
import EditPhoneNumber from "../EditPhoneNumber";
import GyModal from "../GyModal";

const PhoneDialog = (props) => {
  const { editPhone, handleClose, handleSuccess } = props;
  const [open, setOpen] = useState(false);
  const user = getUser();

  useEffect(() => {
    setOpen(editPhone);
  }, [editPhone]);

  return (
    <GyModal open={open} handleClose={handleClose} maxWidth="xs">
      <EditPhoneNumber
        header="CONFIRM YOUR PHONE NUMBER"
        phoneNumber={user?.phone}
        smsOptIn={user?.smsOptIn}
        handleSuccess={handleSuccess}
        hintText="Note: changing this number will update your profile phone number"
        showCheckbox={true}
        IAgreeText="I agree to receive marketing and promotional messages via SMS from GlyMed. Message and data rates may apply."
        saveWidth="100"
      />
    </GyModal>
  );
};

export default PhoneDialog;
