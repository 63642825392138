import Loader from "../components/Loader";
import { useCallback, useEffect } from "react";
import ErrorMessage from "../components/ErrorMessage";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAdminBundleByToken } from "../hooks/useMyRecommendations";

const RecommendationByAdminPage = () => {
  const navigate = useNavigate();
  const { bundleToken } = useParams();
  const { isSuccess, isLoading, isError, error } =
    useGetAdminBundleByToken(bundleToken);

  const handleNavigation = useCallback(() => {
    if (isSuccess) {
      navigate("/cart", {
        state: {
          backgroundLocation: {
            hash: "",
            key: "default",
            pathname: "/",
            search: "",
            state: null,
          },
        },
      });
    }
  }, [navigate, isSuccess]);

  useEffect(() => {
    handleNavigation();
  }, [handleNavigation]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <ErrorMessage
        style={{ minHeight: "52vh" }}
        errorMessage={error.message}
      />
    );
  }
  return null;
};

export default RecommendationByAdminPage;
