import { Box, Grid } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Fragment } from "react";
import { COUPON_TYPES } from "@glymed/types/lib/constants";
import CouponHintBuyTogether from "./CouponHintBuyTogether";
import CouponHintFourSteps from "./CouponHintFourSteps";

const CouponHint = ({ couponHintDetails }) => {
  const { couponHint, couponCode, endDate, autoApply, isHint, meta } =
    couponHintDetails;

    const getHint = () => {
      switch (meta?.couponType) {
        case COUPON_TYPES.BUY_TOGETHER_AND_SAVE:
          return <CouponHintBuyTogether meta={meta} />;
        case COUPON_TYPES.FOUR_STEPS_AND_SAVE:
          return <CouponHintFourSteps meta={meta} />;
        default:
          break;
      }
    } 

  return (
    <Grid
      container
      alignItems={"flex-start"}
      spacing={2}
      style={{
        color: "var(--blue)",
      }}
    >
      <Grid item xs="auto">
        <CheckCircleOutlineIcon />
      </Grid>
      <Grid
        item
        xs
        style={{
          fontSize: "14px",
          fontFamily: "Nimbus-Sans-Regular",
          lineHeight: "18px",
          fontWeight: 400,
        }}
      >
        {isHint ? getHint() : (
          <Fragment>
            {" "}
            <Box>
              {couponHint}{" "}
              {!autoApply && (
                <span>
                  Promo Code: <b>{couponCode}</b>.
                </span>
              )}
            </Box>
            <Box>Valid through {endDate}. Restrictions apply.</Box>
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default CouponHint;
