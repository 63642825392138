import {regen, regenBasic} from "../utils/axios";
import { getUserId } from "../utils/jwt";

export const getCard = async () => {
  const userId = getUserId();
  const URL = `/users/squarecustomer/${userId}`;
  if (!userId) {
    return;
  }
  const response = await regenBasic.get(URL);
  return response.data;
};

export const addCard = async (data) => {
  const userId = getUserId();
  const URL = `/users/squarecustomer/${userId}`;
  if (!userId) {
    return;
  }
  const response = await regen.post(URL, data);
  return response.data;
};

export const setDefaultCard = async (data) => {
  const userId = getUserId();
  if (!userId) {
    return;
  }
  const url = `/users/defaultSquareCustomer/${userId}`;
  const response = await regen.put(url, data);
  return response.data;
};

export const deleteCard = async (data) => {
  const userId = getUserId();
  if (!userId) {
    return;
  }
  const url = `/users/${userId}/squarecustomer/${data?.sourceId}`;
  const response = await regen.delete(url);
  return response.data;
};

export const getRewards = async (data) => {
  const userId = getUserId();

  if (!userId) {
    return;
  }
  const url = `/users/rewardPoints/${userId}`;
  const response = await regenBasic.get(url);
  return response.data;
};
