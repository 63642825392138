export const DEFAULT_APP = "regen";
export const MAX_SHIPPING_RATE_WEIGHT = 151;
export const OUNCE_IN_POUND = 16;
export const RETAIL_ACCOUNT_TYPE = "retail";
export const SITE_SALE_TYPE = "siteSale";
export const DISCOUNT_MODEL_TYPE = "discountModel";
export const DISCOUNT_MODEL_NAME = "DiscountModel";
export const SKU_DISCOUNT_TYPE = "skuDiscount";
export const MYSTRY_PRODUCT_TYPE = "mysteryProduct";
export const SHIPPING_WEIGHT_POUNDS = "pounds";
export const SHIPPING_ADDRESS_CO = "CO";
export const SALE_DISCOUNT_TYPE = "Sale";
export const POINTS_DISCOUNT_TYPE = "points";
export const POINTS = "Points";
export const ORDER_DISCOUNT_TYPE = "orderDiscount";
export const WHOLE_ORDER_DISCOUNT_TYPE = "wholeOrder";
export const PROMO_CODE = "Promo Code";
export const SKU = "sku";
export const ORDER_DISC = "Order Disc";
export const DISCOUNT = "Discount";
export const MILITARY_DISCOUNT_NAME = "MIL Discount";
export const MILITARY_DISCOUNT_TYPE = "militaryDiscount";
export const COUPON_STORE_TYPE = "couponStore";
export const CPN_STORE_SKUS_TYPE = "cpnStoreSkus";
export const CPN_FREE_ITEM_TYPE = "cpnFreeItem";
export const CPN_FREE_ITEM_DISCOUNT_NAME = "Free Item Coupon";

export const COUPON_TYPES = {
  GENERAL: "GENERAL",
  BUY_MORE_SAVE_MORE: "BUY_MORE_SAVE_MORE",
  FREE_ITEM: "FREE_ITEM",
  BUY_THIS_GET_THAT: "BUY_THIS_GET_THAT",
  BUY_TOGETHER_AND_SAVE: "BUY_TOGETHER_AND_SAVE",
  FOUR_STEPS_AND_SAVE: "FOUR_STEPS_AND_SAVE",
};

export const COUPON_TYPES_DISPLAY = {
  [COUPON_TYPES.GENERAL]: "General",
  [COUPON_TYPES.BUY_MORE_SAVE_MORE]: "Buy More, Save More",
  [COUPON_TYPES.FREE_ITEM]: "Free Item",
  [COUPON_TYPES.BUY_THIS_GET_THAT]: "Buy This, Get That",
  [COUPON_TYPES.BUY_TOGETHER_AND_SAVE]: "Buy Together and Save",
  [COUPON_TYPES.FOUR_STEPS_AND_SAVE]: "4 Steps and Save",
};

export const COUPON_OPTIONS_TYPE = {
  PERCENTAGE: "percentage",
  AMOUNT: "amount",
};

export const COUPON_HOW_TO_APPLY = {
  MANUALLY: "manually",
  AUTO_APPLY: "auto-apply",
  NOTIFY_WITH_CODE: "notify-with-code",
};

export const PRODUCT_SIZES = {
  ANY_SIZE: "ANY_SIZE",
};

export const COUPON_DEFAULTS = {
  COUPON_TYPE: COUPON_TYPES.GENERAL,
  DISCOUNT_OPTION: COUPON_OPTIONS_TYPE.PERCENTAGE,
  HOW_TO_APPLY: COUPON_HOW_TO_APPLY.MANUALLY,
  PRODUCT_SIZE: PRODUCT_SIZES.ANY_SIZE,
};

export const FOUR_STEPS = ["Cleanse", "Balance", "Protect", "Treat"];

export const REWARD_TIERS = [
  "Pro",
  "Bronze",
  "Silver",
  "Gold",
  "Platinum",
  "Diamond",
];

export const REWARD_TIERS_MAP = {
  "Pro": "Pro",
  "Bronze": "Bronze",
  "Silver": "Silver",
  "Gold": "Gold",
  "Platinum": "Platinum",
  "Diamond": "Diamond",
};
