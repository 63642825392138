import Box from "@mui/material/Box";
import { Checkbox } from "@mui/material";

const SMSIAgree = (props) => {
  const { checked = false, text = "", handleChange } = props;

  if (text.length === 0) return null;

  return (
    <Box display="flex" flexDirection="row" alignItems="flex-start" gap={1}>
      <Checkbox
        checked={checked}
        sx={{
          "&": {
            padding: 0,
          },
        }}
        onChange={handleChange}
      />
      <p>{text}</p>
    </Box>
  );
};

export default SMSIAgree;
