import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { CART } from "./Constants";
import FreeShippingBanner from "../FreeShippingBanner";
import CouponHint from "../checkout/CouponHint";

const CartHeaedr = ({ data, handleBackdropClick }) => {
  return (
    <>
      <Box mt={5}>
        <IconButton
          size="small"
          onClick={handleBackdropClick}
          sx={{ height: "auto", ml: 2, cursor: "pointer" }}
        >
          <CloseIcon fontSize="small"></CloseIcon>
        </IconButton>
        <h1 style={{ paddingBottom: 0 }} align="center">{CART}</h1>
      </Box>
      <Box mt={2}>
        {data?.couponEligibilityDetails ? (
          <CouponHint couponHintDetails={data.couponEligibilityDetails} />
        ) : <FreeShippingBanner />}
      </Box>
    </>
  );
};

export default CartHeaedr;
