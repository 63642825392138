import { Button, Stack } from "@mui/material";

const ProductQuickLookInventoryInfo = ({
  handleViewMore,
  isLoggedIn,
  handleLogin,
}) => {
  return (
    <>
      <Stack spacing={2}>
        {!isLoggedIn && (
          <Button variant="btn-primary-fluid" onClick={handleLogin}>
            Sign In
          </Button>
        )}
        <Button variant="btn-primary-outlined" onClick={handleViewMore}>
          More Info
        </Button>
      </Stack>
    </>
  );
};

export default ProductQuickLookInventoryInfo;
