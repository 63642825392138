import axios from "axios";

export const stayConnected = async (fields) => {
  const response = await axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTALID}/${process.env.REACT_APP_STAY_CONNECTED_FORM_ID}`,
    {
      fields,
    }
  );
  return response;
};
