export const CONTENT_PAGE_SIZE = 12; //This is number of items displayed on content pages

export const CALENDAR_TABS = [
  {
    itemKey: "hae",
    title: "HAE Events",
  },
  {
    itemKey: "multiday",
    title: "Four Day Events",
  },
  {
    itemKey: "inperson",
    title: "In-Person Classes",
  },
  {
    itemKey: "tradeshow",
    title: "Trade Shows",
  },
  {
    itemKey: "virtual",
    title: "Virtual Classes",
  },
];

export const EVENT_MODE_ONLINE = "Online";

export const PRODUCT_DISCOUNT = "Product Discount";
export const FREE_SHIPPING = "Free Shipping";
export const DEAL_STATUS = {
  UPCOMING: "Upcoming",
  ACTIVE: "Active",
  ENDING: "Ending",
  ENDED: "Ended",
};

export const ADMIN_WITH_PLACE_ORDER_PERMISSION = [
  "jmcdaniel@glymedplus.com",
  "jhall@glymedplus.com",
];
export const ASSETS_BASE_URL = process.env.REACT_APP_REGEN_CONTENT;

let startDate = new Date(Date.UTC(2023, 5, 13, 7, 0, 0));
let endDate = new Date(Date.UTC(2023, 5, 21, 7, 0, 0));
let today = new Date();

export const RETURN_POLICY = (today >= startDate && today < endDate) ? 'glymed_plus_returns_policies_rebrand.html' : 'glymed_plus_returns_policies.html';

export const RESOURCELINKS = [
  {
    title: "How to Add Your REGEN link to Instagram Bio",
    link: "https://www.dropbox.com/scl/fi/memtf3hdubd8s24f7ayoj/Instructions-for-Adding-REGEN-Link-to-Your-Instagram-Bio.pdf?rlkey=j0gn0whpvq69cq4r6jru0kxyr&e=1&st=w7swztma&dl=0",
    isExternal: true,
  },
  {
    title: "How to Share Your REGEN link in Instagram Stories",
    link: "https://www.dropbox.com/scl/fi/87smf1hc6q4qri0v1b33g/Instructions-for-Sharing-Your-REGEN-Link-on-Instagram.pdf?rlkey=861n2bjkpwuc5yswm5v14h5vz&e=1&st=vggasbdw&dl=0",
    isExternal: true,
  },
  {
    title: "How to Add GIF Stickers to Instagram Stories",
    link: "https://www.dropbox.com/scl/fi/r67hjswjty9evylwou710/Instructions-for-Adding-GIF-Sticker-to-Instagram-Stories.pdf?rlkey=pzqfa8amhwqbbefzerb0o47do&e=1&st=vdts5z8f&dl=0",
    isExternal: true,
  },
  {
    title: "REGEN Retail Toolkit",
    link: "https://www.dropbox.com/scl/fo/e4n6782gs23so14w64j66/ADwTep9Yk_mTBBtZis4VTeA?rlkey=pjlfgaii38o242k37ev6bcppn&e=1&st=toico7n4&dl=0",
    isExternal: true,
  },
  {
    title: "Download Desk Signs with QR Code",
    link: "https://www.dropbox.com/scl/fo/14jobrgouqzh0qc0k8lzn/AJb3DZL2-enlMDXw4t2yRW0?rlkey=6ugcu3ryu1qjevtdfvz8ggc62&e=1&st=tu5pyli7&dl=0",
    isExternal: true,
  },
  {
    title: "Download Web Banners",
    link: "https://www.dropbox.com/scl/fo/gvb1w66jx5z2leobof0od/ALoC8AhFOY9bdasCRwHBgQQ?rlkey=trvoip59eeyim7nxdcyh57plx&e=1&st=jbenp8bw&dl=0",
    isExternal: true,
  },
  {
    title: "Create a Bundle",
    link: "/b2b/my-store/my-recommendations",
    isExternal: false,
  },
  {
    title: "REGEN Rewards Information",
    link: "/reward-referral",
    isExternal: false,
  },
];