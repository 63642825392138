import { RewardImageIcon } from "./Rewards.styled";
import { media } from "../../utils/media";
import { ASSETS_BASE_URL } from "../../constants";
import Image from "../shared/Image";

const RewardIcon = ({ tier, ...props }) => {
  if (tier) {
    return (
      <RewardImageIcon
        src={`${ASSETS_BASE_URL}/image/website/content/checkout/${tier.toLowerCase()}.svg`}
        alt={tier}
        {...props}
      />
    );
  }
  return <Image src={media.checkout.bronzeTierImg} alt="Pro" />;
};

export default RewardIcon;
