import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useRecoilState } from "recoil";
import { SubscribeSuccessState } from "../../atoms/productAtoms";

function NotificationSuccess({ message }) {
  const [showAlert, setShowAlert] = useRecoilState(SubscribeSuccessState);

  if (!showAlert) {
    return;
  }

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  return (
    <>
      <Snackbar
        open={showAlert}
        autoHideDuration={4000}
        onClose={handleAlertClose}
      >
        <Alert severity="success">{message}</Alert>
      </Snackbar>
    </>
  );
}

export default NotificationSuccess;
