import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#353954",
    },
    secondary: {
      main: "#5a789f",
    },
  },
  typography: {
    h6: {
      fontFamily: "Nimbus-Sans-Light",
      fontSize: "unset",
    },
    h5: {
      fontFamily: "Nimbus-Sans-Regular",
      fontSize: "unset",
      textTransform: "unset",
    },
    h4: {
      fontFamily: "Nimbus-Sans-Light",
      fontSize: "1.7em",
      textTransform: "unset",
    },
    h3: {
      fontFamily: "Presicav-Regular",
      fontSize: "2.4em",
      textTransform: "unset",
    },
    h2: {
      fontFamily: "Nimbus-Sans-Regular",
      fontSize: "2.4em",
      textTransform: "unset",
    },
    subtitle1: {
      fontFamily: "Nimbus-Sans-Regular",
      fontSize: "1.7em",
      textTransform: "unset",
    },
    subtitle2: {
      fontFamily: "Nimbus-Sans-Regular",
      fontSize: "1.2em",
      textTransform: "unset",
      paddingBottom: "8px",
    },
    fontFamily: "Nimbus-Sans-light",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "var(--gray-400)",
          },
        },
      },
      variants: [
        {
          props: { variant: "danger-link" },
          style: {
            color: "var(--danger)",
            padding: 0,
            height: "auto",
            textDecoration: "none",
            border: "none ",
            fontFamily: "Noto-Mono-Regular",
            fontWeight: "400",
            fontSize: "0.875rem",
            borderRadius: "0",
            textTransform: "capitalize",
            ":hover": {
              color: "var(--danger)",
            },
            ":active": {
              color: "var(--danger)",
            },
          },
        },
        {
          props: { variant: "link" },
          style: {
            color: "var(--blue)",
            padding: 0,
            height: "auto",
            textDecoration: "underline",
            border: "none ",
            fontFamily: "Nimbus-Sans-Light",
            fontWeight: "400",
            fontSize: "1rem",
            borderRadius: "0",
            textTransform: "none",
            ":hover": {
              backgroundColor: "none !impotrant",
              color: "var(--blue)",
            },
            ":active": {
              backgroundColor: "none",
              color: "var(--blue)",
            },
          },
        },
        {
          props: { variant: "btn-primary" },
          style: {
            color: "var(--dark-blue)",
            border: "solid 1px var(--dark-blue)",
            fontFamily: "Nimbus-Sans-Regular",
            fontWeight: "400",
            borderRadius: "0",
            textTransform: "none",
            ":hover": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
              border: "none",
            },
            ":active": {
              border: "none",
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
          },
        },
        {
          props: { variant: "btn-primary-fluid" },
          style: {
            height: "48px",
            width: "100%",
            background: "var(--dark-blue)",
            color: "var(--white)",
            border: "none ",
            fontFamily: "Nimbus-Sans-Regular",
            fontWeight: "200",
            borderRadius: "0",
            textTransform: "none",
            fontSize: "16px",
            ":hover": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
            ":active": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
          },
        },
        {
          props: { variant: "btn-secondary-fluid" },
          style: {
            height: "48px",
            width: "100%",
            background: "var(--gray)",
            color: "var(--dark-blue)",
            border: "none ",
            fontFamily: "Nimbus-Sans-Regular",
            fontWeight: "200",
            borderRadius: "0",
            textTransform: "none",
            fontSize: "1rem",
            ":hover": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
            ":active": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
          },
        },
        {
          props: { variant: "btn-orange-fluid" },
          style: {
            height: "48px",
            width: "100%",
            backgroundColor: "var(--orange)",
            color: "var(--white)",
            border: "none ",
            fontFamily: "Nimbus-Sans-Regular",
            fontWeight: "200",
            borderRadius: "0",
            textTransform: "none",
            fontSize: "1rem",
            ":hover": {
              backgroundColor: "var(--gray-400)",
              color: "var(--blue)",
            },
            ":active": {
              backgroundColor: "var(--blue)",
              color: "var(--white)",
            },
          },
        },
        {
          props: { variant: "btn-secondary" },
          style: {
            background: "var(--dark-blue)",
            color: "var(--white)",
            border: "none ",
            fontFamily: "Nimbus-Sans-Regular",
            fontWeight: "300",
            borderRadius: "0",
            textTransform: "none",
            fontSize: "1rem",
            ":hover": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
            ":active": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
          },
        },
        {
          props: { variant: "btn-secondary-outlined" },
          style: {
            color: "var(--dark-blue)",
            border: "solid 1px var(--dark-blue)",
            fontFamily: "Nimbus-Sans-Light",
            borderRadius: "0",
            textTransform: "none",
            fontWeight: "300",
            fontSize: "1rem",
            ":hover": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
            ":active": {
              color: "var(--dark-blue)",
              backgroundColor: "var(--white)",
            },
            ":focus": {
              border: "solid 1px var(--dark-blue)",
            },
          },
        },
        {
          props: { variant: "btn-gray" },
          style: {
            backgroundColor: "var(--gray)",
            height: "48px",
            color: "var(--dark-blue)",
            border: "none ",
            fontFamily: "Nimbus-Sans-Regular",
            fontWeight: "300",
            borderRadius: "0",
            textTransform: "none",
            fontSize: "1rem",
            ":hover": {
              backgroundColor: "var(--gray-400)",
              color: "var(--dark-blue)",
            },
            ":active": {
              backgroundColor: "var(--gray-400)",
              color: "var(--dark-blue)",
            },
          },
        },
        {
          props: { variant: "btn-icon-primary" },
          style: {
            backgroundColor: "var(--dark-blue)",
            color: "var(--white)",
            border: "none ",
            borderRadius: "50%",
            width: "30px",
            height: "30px",

            minWidth: "unset",
            padding: "0",
            textTransform: "none",
            ":hover": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
            ":active": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
          },
        },
        {
          props: { variant: "btn-prominent" },
          style: {
            background: "var(--dark-blue)",
            color: "var(--white)",
            border: "none ",
            fontFamily: "Nimbus-Sans-Regular",
            fontWeight: "300",
            borderRadius: "0",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            ":hover": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--dark-blue)",
            },
            ":active": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--dark-blue)",
            },
          },
        },
        {
          props: { variant: "btn-prominent-fluid" },
          style: {
            height: "48px",
            width: "100%",
            background: "var(--dark-blue)",
            color: "var(--white)",
            border: "none ",
            fontFamily: "Nimbus-Sans-Regular",
            fontWeight: "200",
            borderRadius: "0",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            ":hover": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
            ":active": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
          },
        },
        {
          props: { variant: "btn-text" },
          style: {
            height: "48px",
            width: "100%",
            backgroundColor: "transparent",
            color: "var(--black)",
            border: "none ",
            fontFamily: "Nimbus-Sans-Regular",
            fontWeight: "400",
            borderRadius: "0",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            fontSize: "18px",
            ":hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              color: "var(--white)",
            },
            ":active": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              color: "var(--white)",
            },
          },
        },
        {
          props: { variant: "btn-primary-outlined" },
          style: {
            color: "var(--dark-blue)",
            border: "solid 1px var(--dark-blue)",
            fontFamily: "Nimbus-Sans-Light",
            borderRadius: "0",
            textTransform: "none",
            fontSize: "1rem",
            ":hover": {
              background:
                "linear-gradient(0deg, rgba(53, 57, 84, 0.5), rgba(53, 57, 84, 0.5)), linear-gradient(90deg, #89C1FF 0%, #7C72EC 99.81%)",
              color: "var(--white)",
            },
            ":active": {
              color: "var(--dark-blue)",
              backgroundColor: "var(--white)",
            },
            ":focus": {
              border: "solid 1px var(--dark-blue)",
            },
          },
        },
      ],
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiFormLabel-root": {
            color: "var(--gray-400)",
            fontFamily: "Nimbus-Sans-Light",
            fontWeight: "200",
            "& .Mui-focused": {
              color: "red",
            },
          },
          "& .MuiInput-root": {
            height: "2rem",
            alignItems: "flex-end",
            fontFamily: "Nimbus-Sans-Light",
            fontWeight: "300",
          },
          "& .MuiInputBase-input": {
            fontFamily: "Nimbus-Sans-Light",
          },
          "& .MuiFormControl-root": {
            marginBottom: "3rem",
          },
          "& .MuiSelect-select": {
            color: "var(--dark-blue)",
            fontFamily: "Nimbus-Sans-Light",
            fontWeight: "200",
            margin: 0,
          },
          "& MuiInputAdornment-root": {
            height: "3rem !important",
          },
        },
      },
    },
  },
});
