import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import LoaderStyle from "../styles/Loader.module.css";
import { useIsMutating } from "react-query";
import { useRecoilValue } from "recoil";
import { GlobalLoaderMessageState } from "../atoms/globalAtom";

const GlobalLoader = ({ showLoader = false }) => {
  const isMutating = useIsMutating();

  const isLoading = !!isMutating || showLoader;
  const message = useRecoilValue(GlobalLoaderMessageState);

  return (
    <Backdrop
      className={
        isLoading ? LoaderStyle["loader-backdrop"] : LoaderStyle["displayNone"]
      }
      open={isLoading}
    >
      <div className={message.length > 0 ? LoaderStyle["loader"] : ""}>
        <Paper className={LoaderStyle["loader-backdrop-indicator"]}>
          <CircularProgress color="inherit" size={25} />
        </Paper>
        {message.length > 0 && (
          <p className={LoaderStyle["loader-indicator-text"]}>{message}</p>
        )}
      </div>
    </Backdrop>
  );
};

export default GlobalLoader;
