import { regenBasic } from "../utils/axios";
import { getUserId } from "../utils/jwt";
import getDeviceId from "../utils/deivceId";
const deviceId = getDeviceId();

///Feature Products
export const getFeatureProducts = async () => {
  const { data } = await regenBasic.get("/featureProducts");
  return data;
};

///Product by sku
export const getProductBySku = async (sku) => {
  const userId = getUserId() || deviceId;
  const url = userId ? `/product/${sku}?userId=${userId}` : `/product/${sku}`;
  const { data } = await regenBasic.get(url);
  return data;
};

export const getProducts = (filterString) => {
  const userId = getUserId();
  const userUrl = userId ? `${userId}` : ``;
  return async ({ pageParam = `/products/${userUrl}` }) => {
    const cleanFilterString =
      filterString?.indexOf("?") === 0
        ? filterString.substring(1)
        : filterString;
    const url = pageParam.includes("?page=")
      ? `${pageParam}&${cleanFilterString}`
      : `${pageParam}${filterString.length > 0 ? `?${cleanFilterString}` : ""}`;
    let response = await regenBasic.get(url);
    const requiredURL = pageParam.split("?page=");
    let nextPageNumber =
      response?.data?.currentPage + 1 > response?.data?.totalPages
        ? null
        : response?.data?.currentPage + 1;
    return {
      ...response?.data,
      nextPage: nextPageNumber
        ? `${requiredURL[0]}?page=${nextPageNumber}`
        : null,
    };
  };
};

export const getCategories = async () => {
  const userId = getUserId();
  const userUrl = userId ? `/${userId}` : ``;
  const response = await regenBasic.get("/products/category" + userUrl);
  return response?.data;
};

export const getFeatureImages = async () => {
  const response = await regenBasic.get("/products/feature");
  return response?.data;
};

export const updateNotify = async (data) => {
  const response = await regenBasic.post("/notify", data);
  return response?.data;
};

export const updateUnsubscribe = async (data) => {
  let url = `/unsubscribe?email=${data?.email}`;
  if (data?.sku) {
    url += `&sku=${data?.sku}`;
  }
  const response = await regenBasic.post(url);
  return response?.data;
};

export const getAllProducts = async () => {
  const { data } = await regenBasic.get(`/products`);
  return data;
};

export const getProductByIDs = async (ids) => {
  const { data } = await regenBasic.post("/products/ids", { ids });
  return data;
};

export const getConcerns = async () => {
  const userId = getUserId();
  const url = userId ? `/products/concerns/${userId}` : `/products/concerns`;
  const { data } = await regenBasic.get(url);
  return data;
};
