import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";

//Icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from '@mui/icons-material/X';

const SocialMedia = ({ color, className }) => {
  return (
    <div className={className}>
      <ul>
        <li>
          <SocialLink
            color={color}
            target="_blank"
            href={"https://facebook.com/glymedplus"}
          >
            <FacebookIcon />
          </SocialLink>
        </li>
        <li>
          <SocialLink
            color={color}
            target="_blank"
            href={"https://www.instagram.com/glymedplus/"}
          >
            <InstagramIcon />
          </SocialLink>
        </li>
        <li>
          <SocialLink
            color={color}
            target="_blank"
            href={"https://x.com/glymedplus"}
          >
            <XIcon />
          </SocialLink>
        </li>
        <li>
          <SocialLink
            color={color}
            target="_blank"
            href={"https://pinterest.com/glymedplus"}
          >
            <PinterestIcon />
          </SocialLink>
        </li>
        <li>
          <SocialLink
            color={color}
            target="_blank"
            href={"https://www.youtube.com/c/GlymedPlusProvo"}
          >
            <YouTubeIcon />
          </SocialLink>
        </li>
        <li>
          <SocialLink
            color={color}
            target="_blank"
            href={"https://www.linkedin.com/company/glymed-plus/"}
          >
            <LinkedInIcon />
          </SocialLink>
        </li>
      </ul>
    </div>
  );
};

const SocialLink = styled(Link)(({ color }) => ({
  color: color,
}));

export default SocialMedia;
