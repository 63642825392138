import { regen, regenBasic, regenBasicV2 } from "../utils/axios";

//rewards points
export const getStoreLabel = async (accountId) => {
  const { data } = await regenBasic.get(`users/storeLabel/${accountId}`);
  return data;
};

export const getTopProducts = async (userId, days, limit) => {
  const response = await regenBasic.get(
    `user/${userId}/topProducts?days=${days}&limit=${limit}`
  );
  return response?.data;
};

export const getWeeklySales = async (userId, week) => {
  const response = await regenBasic.get(
    `user/${userId}/weeklySale?week=${week}`
  );
  return response?.data;
};

export const addStoreLabel = async (payload) => {
  const { accountId, storeLabel } = payload;
  const { data } = await regen.post(`/accounts/${accountId}/storeLabels`, {storeLabel});
  return data;
};

export const getStoreLabels = async (accountId) => {
  const { data } = await regenBasic.get(`accounts/${accountId}/storeLabels`);
  return data;
};

export const updateStoreLabel = async (payload) => {
  const { accountId, newStoreLabel, storeLabel } = payload;
  const { data } = await regen.put(`/accounts/${accountId}/storeLabels`, {
    newStoreLabel,
    storeLabel,
  });
  return data;
};

export const uploadStoreLogo = async (data) => {
  const { accountId, payload } = data;
  const response = await regenBasicV2({
    method: "POST",
    url: `storeLabels/${accountId}`,
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response.data;
};

export const getStoreLogo = async (accountId) => {
  const response = await regenBasicV2({
    method: "GET",
    url: `storeLabels/${accountId}`,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

export const removeStoreLogo = async (accountId) => {
  const response = await regenBasicV2({
    method: "DELETE",
    url: `storeLabels/${accountId}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
