import SocialMedia from "../components/SocialMedia";
import { Outlet, useNavigate } from "react-router-dom";
import css from "../styles_b2b/RewardReferrals.module.css";
import { Button, Container, Grid, Box } from "@mui/material";
import ResponsiveBannerImage from "../components/ResponsiveBannerImage";
import StayConnected from "../components/StayConnected";
import HowToAccessReward from "../components_b2b/HowToAccessReward";

const RewardReferrals = ({ data }) => {
  const {
    banner,
    benefits,
    regenRewards,
    orderDiscount,
    minimumOrder,
    freeEducation,
    earnPoints,
    gaain,
    followUsTitle,
    stayConnected
  } = data || {};

  const navigate = useNavigate()

  const gotoAboutRegenPage = () => navigate("/regen-retail");
  const referPro = () => {
    window.open(
      "https://forms.monday.com/forms/9164134ff592cb5b7edd000bf5366b17?r=use1"
    );
  }

  return (
    <>
      <ResponsiveBannerImage banner={banner} />
      <Container maxWidth="lg" className={css.container}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Box className={css.boxType1}>
              <h1 className={css.title}>{benefits?.title}</h1>
              <h2 className={css.secondTitle}>{benefits?.subTitle}</h2>
              <p className={css.description}>{benefits.description}</p>
              <ul className={css.listHolder}>
                {benefits?.proBenefits?.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
              <Button
                className={css.btnStyle}
                variant="btn-secondary"
                onClick={gotoAboutRegenPage}
              >
                {benefits.ctaText}
              </Button>
            </Box>
            <Box className={css.boxType1}>
              <h2 className={css.secondTitle}>{regenRewards?.title}</h2>
              <p className={css.description}>{regenRewards.description}</p>
              <ul className={css.listHolder}>
                {regenRewards?.rewards?.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </Box>
            <Box className={css.boxType2}>
              <h2 className={css.secondTitle}>{orderDiscount?.title}</h2>
              <p className={css.description}>
                {orderDiscount.description}
              </p>
            </Box>
            <Box className={css.boxType2}>
              <h2 className={css.secondTitle}>{minimumOrder?.title}</h2>
              <p className={css.description}>
                {minimumOrder.description}
              </p>
            </Box>
            <Box className={css.boxType2}>
              <h2 className={css.secondTitle}>{freeEducation?.title}</h2>
              <p className={css.description}>
                {freeEducation.description}
              </p>
            </Box>
            <Box className={css.boxType2}>
              <h2 className={css.secondTitle}>{earnPoints?.title}</h2>
              <p className={css.description}>
                {earnPoints.description}
              </p>
              <p className={css.description}>
                {earnPoints.note}
              </p>
              <Button
                className={css.btnStyle}
                variant="btn-secondary"
                onClick={referPro}
              >
                {earnPoints.ctaText}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box className={css.gaainBox}>
              <h3 sx={{ pb: 2.5 }}>{gaain?.title}</h3>
              <p className={css.description}>{gaain.description}</p>
            </Box>
            <HowToAccessReward />
            <Box className={css.followUsBox}>
              <Box className={css.followUsHeader}>
                <h3 sx={{ pb: 2.5 }}>{followUsTitle}</h3>
                <SocialMedia color={"#000"} className={css["social-media"]} />
              </Box>
              <Box>
                <h3 sx={{ pb: 2.5 }}>{stayConnected.title}</h3>
                <p className={css.description}>{stayConnected.description}</p>
                <StayConnected />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Outlet />
    </>
  );
};

export default RewardReferrals;