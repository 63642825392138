import Box from "@mui/material/Box";
import css from "../../styles/SkuInfo.module.css";
import classNames from "classnames";

const Sku = ({ sku, handleSelectSku, selectedSku }) => {
  const isSelected = sku?.sku === selectedSku;
  const handleSelect = () => {
    handleSelectSku(sku?.sku);
  };

  return (
    <Box className={css.sku} onClick={handleSelect}>
      <Box
        className={
          isSelected
            ? classNames(css.thumbnail, css.selectedThumbnail)
            : css.thumbnail
        }
      >
        <img className={css.thumbnailImg} src={sku?.thumbnail} alt={sku?.shortName} />
      </Box>
      <p
        className={
          isSelected
            ? classNames(css.skuType, css.selectedSkuType)
            : css.skuType
        }
      >
        {sku?.apparelStyle ? sku.apparelStyle : sku.skuType}
      </p>
    </Box>
  );
};

export default Sku;
