import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ContentfulClient, ContentfulProvider } from "react-contentful";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@emotion/react";
import { QueryClientProvider, QueryClient } from "react-query";
import { IntercomProvider } from 'react-use-intercom';
import DisableRightClick from "./components/DisableRightClick";
import { RecoilRoot } from "recoil";
import { theme } from "./utils/theme";
import App from "./App";
import "./index.css";
import "./App.css";
import "./utils/Sentry";
//Swiper css
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const contentfulClient = new ContentfulClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "staging",
});

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <ContentfulProvider client={contentfulClient}>
          <BrowserRouter>
            <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
              <DisableRightClick>
                <App />
              </DisableRightClick>
            </IntercomProvider>
          </BrowserRouter>
        </ContentfulProvider>
      </ThemeProvider>
    </RecoilRoot>
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
