import { atom } from "recoil";

export const OpenSelectPaymentState = atom({
  key: "OpenSelectPayment",
  default: false,
});

export const OpenAddNewPaymentState = atom({
  key: "OpenAddNewPayment",
  default: false,
});

export const PaymentConfirmState = atom({
  key: "PaymentConfirm",
  default: false,
});

export const OpenPaymentState = atom({
  key: "OpenPayment",
  default: false,
});

export const OpenReviewOrderState = atom({
  key: "OpenReviewOrder",
  default: false,
});

export const currentRewardPointsState = atom({
  key: "CurrentRewardPoints",
  default: 0,
});

export const ShippingInstructionsState = atom({
  key: "ShippingInstructions",
  default: "",
});

export const EnablePaymentState = atom({
  key: "EnablePayment",
  default: false,
});

export const RewardsNextTierMinSpendState = atom({
  key: "RewardsNextTierMinSpend",
  default: 0,
});

export const RewardsMaxRedeemableState = atom({
  key: "RewardsMaxRedeemable",
  default: 0,
});

export const OpenShippingState = atom({
  key: "OpenShippingState",
  default: true,
});