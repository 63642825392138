import React from "react";
import { Box } from "@mui/material";
import { getFormattedPrice } from "../../utils";
import css from "../../styles/SkuInfo.module.css";

const SkuPriceWithDiscount = ({ sku }) => {
  return (
    <Box>
      {sku?.discountAmount > 0 ? (
        <Box className={css.discountBox}>
          <p className={css.bodyRegularL}>
            {`$${getFormattedPrice(sku.discountedAmount, 2)}`}
          </p>
          <p style={{ marginLeft: 1.25, padding: 0, color: "var(--danger)" }}>
            <strike>${getFormattedPrice(sku.price, 2)}</strike>
          </p>
        </Box>
      ) : (
        <p className={css.bodyRegularL}>
          {`$${getFormattedPrice(sku.price, 2)}`}
        </p>
      )}

      <p className={css.bodyRegular}>
        {sku.sku} ({sku.productSize})
      </p>
    </Box>
  );
};
export default SkuPriceWithDiscount;
