import Box from "@mui/material/Box";
import RewardsOnOrder from "./RewardsOnOrder";
import { getFormattedPrice } from "../../utils";
import css from "../../styles_web/CartFoooter.module.css";
import { StyledBodyBold, StyledBodyRegular } from "../../styled";
import Button from "@mui/material/Button";

const CartFooter = ({
  rewardsData,
  data,
  handleCheckout,
  continueShopping,
}) => {
  return (
    <Box
      className={css.cartFooter}
      sx={{
        width: { xs: "100%", md: "50%", lg: "40%", xl: "30%" },
      }}
    >
      <RewardsOnOrder rewardsData={rewardsData} />
      <Box className={css.wrapper}>
        <Box className={css.subTotalBox}>
          <StyledBodyRegular className={css.subTotalText}>
            Subtotal ({data?.items?.length ? data?.orderTotal?.numItems : 0}{" "}
            {data?.orderTotal?.numItems > 1 ? "items" : "item"}):
          </StyledBodyRegular>
          <h4 className={css.subTotalText}>
            {data && data.totalAmountWithoutTax
              ? `$${getFormattedPrice(data.totalAmountWithoutTax, 2)}`
              : "$0.00"}
          </h4>
        </Box>
        <Button onClick={handleCheckout} fullWidth variant="btn-secondary">
          Checkout
        </Button>
        <Button
          onClick={continueShopping}
          sx={{
            marginTop: "10px",
            textTransform: "capitalize",
            borderRadius: "0",
          }}
          fullWidth
          variant="outlined"
        >
          <StyledBodyBold style={{ padding: 0 }}>
            Continue Shopping
          </StyledBodyBold>
        </Button>
      </Box>
    </Box>
  );
};

export default CartFooter;
